import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { EhrComponent } from './components/ehr/ehr.component';
import { LoginComponent } from './components/ehr/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { InPatientsComponent } from './components/ehr/in-patients/in-patients.component';
import { DashboardComponent } from './components/ehr/dashboard/dashboard.component';
import { PatientsComponent } from './components/ehr/patients/patients.component';
import { CustomReuseStrategy } from './CustomReuseStrategy';
import { RegisterPatientComponent } from './components/ehr/register-patient/register-patient.component';
import { ProfileComponent } from './components/ehr/patients/profile/profile.component';
import { EpisodeOfCareComponent } from './components/ehr/patients/episode-of-care/episode-of-care.component';
import { EocGuard } from './guards/eoc.guard';
import { UserManagementComponent } from './components/ehr/user-management/user-management.component';
import { UserManagementEditComponent } from './components/ehr/user-management/user-management-edit/user-management-edit.component';
import { UserManagementNewComponent } from './components/ehr/user-management/user-management-new/user-management-new.component';
import { PatientsGocComponent } from './components/ehr/patients-goc/patients-goc.component';
import { ModuleGuard } from './guards/module.guard';
import { AdmissionComponent } from './components/ehr/admission/admission.component';
import { AdmissionProfileComponent } from './components/ehr/admission/admission-profile/admission-profile.component';
import { DashboardAmenComponent } from './components/ehr/dashboard-amen/dashboard-amen.component';
import { CanLeaveGuard } from './guards/can-leave.guard';
import { WorkspaceComponent } from './components/ehr/workspace/workspace.component';
import { NewloginComponent } from './components/newlogin/newlogin.component';
import { NewforgotComponent } from './components/newforgot/newforgot.component';
import { NewresetComponent } from './components/newreset/newreset.component';
import { NotFoundComponent } from './components/ehr/not-found/not-found.component';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { SmartHealthLinksComponent } from './components/smart-health-links/smart-health-links.component';

const routes: Routes = [
  {
    path: 'resetUsername',
    component: ForgotUsernameComponent,
  },
  {
    path: 'ehr',
    component: EhrComponent,
    data: { reuse: true },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'not-found',
        component: NotFoundComponent,
        data: { reuse: false },
      },
      {
        path: 'workspace',
        component: WorkspaceComponent,
        data: { reuse: false },
        canActivate: [ModuleGuard],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { reuse: false },
        canActivate: [ModuleGuard],
      },
      {
        path: 'dashboardamen',
        component: DashboardAmenComponent,
        data: { reuse: false },
        canActivate: [ModuleGuard],
      },
      {
        path: 'inPatients',
        component: InPatientsComponent,
        data: { reuse: true, icu: false },
        canActivate: [ModuleGuard],
      },
      {
        path: 'inPatientsICU',
        component: InPatientsComponent,
        data: { reuse: true, icu: true },
        canActivate: [ModuleGuard],
      },
      //uncomment this for detailed profile page instead of modal in case we dont want to reuse admission as detailed profile
      // {
      //   path: 'detailedProfile',
      //   component: DetailedProfileComponent,
      //   // data: { reuse: true },
      //   // canActivate: [ModuleGuard],
      // },
      {
        path: 'allPatients',
        component: PatientsComponent,
        data: { reuse: true },
        canActivate: [ModuleGuard],
      },
      {
        path: 'allPatientsPilotStudy',
        component: PatientsGocComponent,
        data: { reuse: false },
      },
      {
        path: 'admission',
        component: AdmissionComponent,
        data: { reuse: false },
        canActivate: [ModuleGuard],
      },
      {
        path: 'userManagement',
        component: UserManagementComponent,
        data: { reuse: false },
        canActivate: [ModuleGuard],
      },
      {
        path: 'userProfile',
        component: UserManagementEditComponent,
        data: { reuse: false },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: 'userRegistration',
        component: UserManagementNewComponent,
        data: { reuse: false },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: 'register-patient',
        component: RegisterPatientComponent,
        data: { reuse: false },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { reuse: false },
      },
      {
        path: 'admission-profile',
        component: AdmissionProfileComponent,
        data: { reuse: false },
      },
      {
        path: 'episodeOfCare/:patientID/:eocID/:visitID',
        component: EpisodeOfCareComponent,
        data: { reuse: false },
        canActivate: [EocGuard],
        canDeactivate: [CanLeaveGuard],
      },
      {
        path: 'inPatientView/:patientID/:eocID/:visitID',
        component: EpisodeOfCareComponent,
        data: { reuse: false },
        canActivate: [EocGuard],
        canDeactivate: [CanLeaveGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { reuse: false },
  },
  { path: 'newlogin', component: NewloginComponent, data: { reuse: false } },
  { path: 'newforgot', component: NewforgotComponent, data: { reuse: false } },
  { path: 'newreset', component: NewresetComponent, data: { reuse: false } },
  {
    path: 'SmartHealthLink/:shl',
    component: SmartHealthLinksComponent,
    data: { reuse: false },
  },
  { path: '**', redirectTo: '/login', data: { reuse: false } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
  ],
  declarations: [],
})
export class AppRoutingModule {}
