import { Component } from '@angular/core';
import { CtParametersAddEditComponent } from './ct-parameters-add-edit/ct-parameters-add-edit.component';
import { Subject, takeUntil } from 'rxjs';
import { ICtParametersDetailed } from 'src/app/interfaces/CtParameters/ICtParametersDetailed';
import { CtParametersService } from 'src/app/services/ctparameters.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ct-parameters',
  templateUrl: './ct-parameters.component.html',
  styleUrls: ['./ct-parameters.component.css'],
})
export class CtParametersComponent {
  public addComp = CtParametersAddEditComponent;

  private destroy$ = new Subject<void>();

  ctParameters?: ICtParametersDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly ctParametersService: CtParametersService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: ICtParametersDetailed) {
    this.ctParametersService.ctParametersModalSub.next(true);
    this.ctParametersService.ctParametersEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('3D-CT Parameter')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ctParametersService.deleteCtParameters(id.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: `${this.translate.instant(
                '3D-CT Parameter record deleted successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.ctParametersService.deleteCtParametersFromTable(id.toString());
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Unable to delete 3D-CT Parameter record'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('3D-CT Parameters record is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
  ngOnInit() {
    this.ctParametersService.ctParametersLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.ctParametersService.ctParametersDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICtParametersDetailed[]) => {
        this.ctParameters = data;
      });

    this.ctParametersService.getData();
  }
}
