import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { IPregnancyOutcomeDetailed } from '../../../../../../interfaces/PregnancyOutcome/IPregnancyOutcomeDetailed';
import { PregnancyOutcomeAddEditComponent } from '../pregnancy-outcome/pregnancy-outcome-add-edit/pregnancy-outcome-add-edit.component';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pregnancy-outcome',
  templateUrl: './pregnancy-outcome.component.html',
  styleUrls: ['./pregnancy-outcome.component.css'],
})
export class PregnancyOutcomeComponent {
  public addComp = PregnancyOutcomeAddEditComponent;
  // public expandComp = PregnancyOutcomeExpandComponent;

  private destroy$ = new Subject<void>();

  pregnancyOutcome?: IPregnancyOutcomeDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.pregnancyOutcomeService.pregnancyOutcomeLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.pregnancyOutcomeService.pregnancyOutcomeDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IPregnancyOutcomeDetailed[]) => {
        this.pregnancyOutcome = data;
      });

    this.pregnancyOutcomeService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IPregnancyOutcomeDetailed) {
    this.pregnancyOutcomeService.pregnancyOutcomeModalSub.next(true);
    this.pregnancyOutcomeService.pregnancyOutcomeEditSub.next(item);
  }

  deleteFun(pregnancyOutcomeId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Pregnancy Outcome')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.pregnancyOutcomeService
          .deletePregnancyOutcome(pregnancyOutcomeId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Pregnancy Outcome deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pregnancyOutcomeService.deletePregnancyOutcomeFromTable(
                pregnancyOutcomeId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to delete Pregnancy Outcome'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Pregnancy Outcome is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  // expandSet = new Set<number>();
  // onExpandChange(id: number, checked: boolean): void {
  //   if (checked) {
  //     this.expandSet.add(id);
  //   } else {
  //     this.expandSet.delete(id);
  //   }
  // }
}
