<nz-modal nzNoAnimation #moveModalRef nzSize [(nzVisible)]="this.parent.isMoveVisible" [nzTitle]="
    'MovePatientToBed'
      | translate : { name: selectedPatientFullName || 'Patient' }
  " (nzOnCancel)="handleMoveCancel()" nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null"
    class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="moveForm" (ngSubmit)="moveBedForm()" [ngClass]="{
        'move-submitted': moveSubmitted,
        'move-not-submitted': !moveSubmitted
      }">
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
                    <label>{{ "Bed" | translate }}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
                    <nz-select class="patient-dropdown" nzShowSearch nzAllowClear [nzPlaceHolder]="'Bed' | translate"
                        [nzLoading]="isMoveLoading" formControlName="newBedValue">
                        <ng-container *ngFor="let et of moveBeds">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
                    <label>{{ "Notes" | translate
                        }}<span class="optional">{{
                            "(optional)" | translate
                            }}</span></label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80" fxLayoutAlign="start center">
                    <textarea type="text" rows="2" nz-input class="inputs width-100"
                        placeholder="{{ 'Notes' | translate }}" formControlName="noteValue"></textarea>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isMoveLoading"
                    [disabled]="isMoveLoading">
                    {{ "Save" | translate }}
                </button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleMoveCancel()"
                    [disabled]="isMoveLoading">
                    {{ "Cancel" | translate }}
                </button>
            </div>
        </form>
    </ng-container>
    <ng-template>
        <button type="submit">{{ "Save" | translate }}</button>
    </ng-template>
</nz-modal>