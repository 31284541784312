import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { IMedicationDetailed } from 'src/app/interfaces/Medication/IMedicationDetailed';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import Swal from 'sweetalert2';
import { MedicationExpandComponent } from '../medication-expand/medication-expand.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-current-medication',
  templateUrl: './current-medication.component.html',
  styleUrls: ['./current-medication.component.css'],
})
export class CurrentMedicationComponent {
  // public expandComp = CurrentMedicationExpandComponent;

  private destroy$ = new Subject<void>();

  expandComp = MedicationExpandComponent;

  currentMedication?: IGetList<IMedicationDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentMedicationService.currentMedicationLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.currentMedicationService.noKnownLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.currentMedicationService.currentMedicationDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IMedicationDetailed>) => {
        this.currentMedication = data;
      });

    this.currentMedicationService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IMedicationDetailed) {
    this.currentMedicationService.medicationEditSub.next(item);

    this.currentMedicationService.medicationOpenSub.next(true);
  }

  deleteFun(medicationId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Current Medication')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentMedicationService
          .deleteCurrentMedication(medicationId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Current Medication deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.currentMedicationService.getData();
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to delete Current Medication'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Current Medication is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.currentMedicationService.noKnownLoadingSub.next(true);
        this.currentMedicationService.addNoKnownCurrentMedication().subscribe({
          next: () => {
            Swal.fire({
              text: `${this.translate.instant(
                'No known Medication set successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.currentMedicationService.getData();
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Unable to set No Known Medication'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error setting No Known Medication:', err);
          },
        });
      } else {
        if (unknownid) {
          this.currentMedicationService.noKnownLoadingSub.next(true);
          this.currentMedicationService
            .deleteNoKnownCurrentMedication(unknownid)
            .subscribe({
              next: () => {
                Swal.fire({
                  text: `${this.translate.instant(
                    'No known Medication unset successfully'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });

                this.currentMedicationService.getData();
              },
              error: (err) => {
                Swal.fire({
                  text: `${this.translate.instant(
                    'Unable to unset No Known Medication'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });
                console.error('Error unsetting No Known Medication:', err);
              },
            });
        }
      }
    }
  }
}
