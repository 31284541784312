import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/ehr/login/login.component';
import { initKeycloak } from './init-keycloak';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { CustomPaginatorIntl } from './services/customPaginator.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LogoutComponent } from './components/ehr/logout/logout.component';
import { EhrComponent } from './components/ehr/ehr.component';
import { AuthGuard } from './guards/auth.guard';
import { HeaderComponent } from './components/ehr/header/header.component';
import { MenuService } from './services/menu.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PatientsComponent } from './components/ehr/patients/patients.component';
import { PatientService } from './services/patient.service';
import { InPatientsComponent } from './components/ehr/in-patients/in-patients.component';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { DashboardComponent } from './components/ehr/dashboard/dashboard.component';
import { LocaleService } from './services/locale.service';
import { UserService } from './services/user.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddPatientService } from './services/addpatient.service';
import { RegisterPatientComponent } from './components/ehr/register-patient/register-patient.component';
import { MatStepperModule } from '@angular/material/stepper';
import { PersonalInfoComponent } from './components/ehr/register-patient/personal-info/personal-info.component';
import { IdentifiersComponent } from './components/ehr/register-patient/identifiers/identifiers.component';
import { AddressComponent } from './components/ehr/register-patient/address/address.component';
import { InsuranceComponent } from './components/ehr/register-patient/insurance/insurance.component';
import { ProfileComponent } from './components/ehr/patients/profile/profile.component';
import { PatientsHeaderComponent } from './components/ehr/patients/patients-header/patients-header.component';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { StoreModule } from '@ngrx/store';
import { menuReducer } from './reducers/menu.reducer';
import { EpisodeOfCareComponent } from './components/ehr/patients/episode-of-care/episode-of-care.component';
import { MedicalHistorySectionComponent } from './components/ehr/patients/components/medical-history-section/medical-history-section.component';
import { AllergiesComponent } from './components/ehr/patients/components/alerts-section/allergies/allergies.component';
import { ClinicalExaminationSectionComponent } from './components/ehr/patients/components/clinical-examination-section/clinical-examination-section.component';
import { AllergyService } from './services/allergy.service';
import { CurrentProblemsComponent } from './components/ehr/patients/components/medical-problems-section/current-problems/current-problems.component';
import { CurrentProblemsService } from './services/currentProblems.service';
import { ResolvedProblemsComponent } from './components/ehr/patients/components/medical-history-section/resolved-problems/resolved-problems.component';
import { ReusableTableComponent } from './common/reusable-table/reusable-table.component';
import { TestComponent } from './common/test/test.component';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { EpisodeOfCareService } from './services/episodeOfCare.service';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { MedicalDeviceComponent } from './components/ehr/patients/components/medical-problems-section/medical-device/medical-device.component';
import { ProcedureComponent } from './components/ehr/patients/components/medical-problems-section/procedure/procedure.component';
import { ProcedureService } from './services/procedure.service';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { EocSectionComponent } from './components/ehr/patients/components/eoc-section/eoc-section.component';
import { AlertsSectionComponent } from './components/ehr/patients/components/alerts-section/alerts-section.component';
import { MedicalProblemsSectionComponent } from './components/ehr/patients/components/medical-problems-section/medical-problems-section.component';
import { el_GR, en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { PreviewSummaryComponent } from './components/ehr/patients/preview-summary/preview-summary.component';
import { UserManagementComponent } from './components/ehr/user-management/user-management.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { UserManagementService } from './services/userManagement.service';
import { UserManagementEditComponent } from './components/ehr/user-management/user-management-edit/user-management-edit.component';
import { UserManagementNewComponent } from './components/ehr/user-management/user-management-new/user-management-new.component';
import { DetailedProfileComponent } from './components/ehr/patients/detailed-profile/detailed-profile.component';
import { VaccinationComponent } from './components/ehr/patients/components/medical-history-section/vaccination/vaccination.component';
import { VaccinationService } from './services/vaccination.service';
import { MedicalAlertComponent } from './components/ehr/patients/components/alerts-section/medical-alert/medical-alert.component';
import { MedicalAlertService } from './services/medicalAlert.service';
import { MedicalHistoryComponent } from './components/ehr/patients/components/medical-history-section/medical-history/medical-history.component';
import { MedicalHistoryService } from './services/medicalHistory.service';
import { MedicalDeviceService } from './services/medicalDevice.service';
import { ResolvedProblemsService } from './services/resolvedProblems.service';
import { PregnancySectionComponent } from './components/ehr/patients/components/pregnancy-section/pregnancy-section.component';
import { PregnancyHistoryComponent } from './components/ehr/patients/components/pregnancy-section/pregnancy-history/pregnancy-history.component';
import { PregnancyHistoryService } from './services/pregnancyHistory.service';
import { PregnancyOutcomeComponent } from './components/ehr/patients/components/pregnancy-section/pregnancy-outcome/pregnancy-outcome.component';
import { PregnancyOutcomeService } from './services/pregnancyOutcome.service';
import { PregnancyStatusService } from './services/pregnancyStatus.service';
import { PregnancyStatusComponent } from './components/ehr/patients/components/pregnancy-section/pregnancy-status/pregnancy-status.component';
import { ImagingSectionComponent } from './components/ehr/patients/components/imaging-section/imaging-section.component';
import { ImagingFilesComponent } from './components/ehr/patients/components/imaging-section/imaging-files/imaging-files.component';
import { ImagingService } from './services/imaging.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileSaverModule } from 'ngx-filesaver';
import { ImagingFilesAddComponent } from './components/ehr/patients/components/imaging-section/imaging-files/imaging-files-add/imaging-files-add.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { LaboratorySectionComponent } from './components/ehr/patients/components/laboratory-section/laboratory-section.component';
import { LaboratoryFileAddComponent } from './components/ehr/patients/components/laboratory-section/laboratory-file/laboratory-file-add/laboratory-file-add.component';
import { LaboratoryFileService } from './services/laboratoryFile.service';
import { LaboratoryFileComponent } from './components/ehr/patients/components/laboratory-section/laboratory-file/laboratory-file.component';
import { SocialHistorySectionComponent } from './components/ehr/patients/components/social-history-section/social-history-section.component';
import { SocialHistoryComponent } from './components/ehr/patients/components/social-history-section/social-history/social-history.component';
import { HealthCarePlanSectionComponent } from './components/ehr/patients/components/health-care-plan-section/health-care-plan-section.component';
import { CarePlanComponent } from './components/ehr/patients/components/health-care-plan-section/care-plan/care-plan.component';
import { CarePlanService } from './services/carePlan.service';
import { SocialHistoryService } from './services/socialHistory.service';
import { MedicationSummarySectionComponent } from './components/ehr/patients/components/medication-summary-section/medication-summary-section.component';
import { MedicationAddComponent } from './components/ehr/patients/components/medication-summary-section/medication-add/medication-add.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { CurrentMedicationComponent } from './components/ehr/patients/components/medication-summary-section/current-medication/current-medication.component';
import { PastMedicationComponent } from './components/ehr/patients/components/medication-summary-section/past-medication/past-medication.component';
import { CurrentMedicationService } from './services/currentMedication.service';
import { PastMedicationService } from './services/pastMedication.service';
import { PharmacyService } from './services/pharmacy.service';
import { TravelHistorySectionComponent } from './components/ehr/patients/components/travel-history-section/travel-history-section.component';
import { TravelHistoryComponent } from './components/ehr/patients/components/travel-history-section/travel-history/travel-history.component';
import { TravelHistoryService } from './services/travelHistory.service';
import { MedicationExpandComponent } from './components/ehr/patients/components/medication-summary-section/medication-expand/medication-expand.component';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { VitalSignsComponent } from './components/ehr/patients/components/daily-monitoring-section/vital-signs/vital-signs.component';
import { DailyMonitoringSectionComponent } from './components/ehr/patients/components/daily-monitoring-section/daily-monitoring-section.component';
import { VitalSignService } from './services/vitalSign.service';
import { VitalSignsChartComponent } from './components/ehr/patients/components/daily-monitoring-section/vital-signs/vital-signs-chart/vital-signs-chart.component';
import { DashboardInpatientByAgeComponent } from './components/ehr/dashboard/dashboard-inpatient-by-age/dashboard-inpatient-by-age.component';
import { DashboardInpatientByGenderComponent } from './components/ehr/dashboard/dashboard-inpatient-by-gender/dashboard-inpatient-by-gender.component';
import { DashboardBedAvailabilityByWardComponent } from './components/ehr/dashboard/dashboard-bed-availability-by-ward/dashboard-bed-availability-by-ward.component';
import { DashboardBedAvailabilityByDayComponent } from './components/ehr/dashboard/dashboard-bed-availability-by-day/dashboard-bed-availability-by-day.component';
import { DischargeService } from './services/discharge.service';
import { DashboardAdmissionReasonComponent } from './components/ehr/dashboard/dashboard-admission-reason/dashboard-admission-reason.component';
import { DashboardAdmissionsAndDischargesComponent } from './components/ehr/dashboard/dashboard-admissions-and-discharges/dashboard-admissions-and-discharges.component';
import { DashboardDischargeTypesComponent } from './components/ehr/dashboard/dashboard-discharge-types/dashboard-discharge-types.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ArterialBloodGasComponent } from './components/ehr/patients/components/daily-monitoring-section/arterial-blood-gas/arterial-blood-gas.component';
import { ArterialBloodGasService } from './services/arterialBloodGas.service';
import { GlasgowComponent } from './components/ehr/patients/components/daily-monitoring-section/glasgow/glasgow.component';
import { GlasgowService } from './services/glasgow.service';
import { TenantSettingsService } from './services/tenantSettings.service';
import { TracheostomySectionComponent } from './components/ehr/patients/components/tracheostomy-section/tracheostomy-section.component';
import { CtParametersComponent } from './components/ehr/patients/components/tracheostomy-section/ct-parameters/ct-parameters.component';
import { CtParametersService } from './services/ctparameters.service';
import { TubeCharacteristicsService } from './services/tubeCharacteristics.service';
import { TubeCharacteristicsComponent } from './components/ehr/patients/components/tracheostomy-section/tube-characteristics/tube-characteristics.component';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { EtiologySectionComponent } from './components/ehr/patients/components/etiology-section/etiology-section.component';
import { EtiologyComponent } from './components/ehr/patients/components/etiology-section/etiology/etiology.component';
import { ReusableReverseTableComponent } from './common/reusable-reverse-table/reusable-reverse-table.component';
import { ComorbiditySectionComponent } from './components/ehr/patients/components/comorbidity-section/comorbidity-section.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PreciousAiComponent } from './components/ehr/patients/components/precious-ai/precious-ai.component';
import { AssessmentSectionComponent } from './components/ehr/patients/components/precious-ai/assessment-section/assessment-section.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { EtiologyService } from './services/etiology.service';
import { ComplicationSectionComponent } from './components/ehr/patients/components/complication-section/complication-section.component';
import { ComplicationComponent } from './components/ehr/patients/components/complication-section/complication/complication.component';
import { ComplicationService } from './services/complication.service';
import { CapnographyService } from './services/capnography.service';
import { CapnographySectionComponent } from './components/ehr/patients/components/capnography-section/capnography-section.component';
import { CapnographyComponent } from './components/ehr/patients/components/capnography-section/capnography/capnography.component';
import { ComorbidityComponent } from './components/ehr/patients/components/comorbidity-section/comorbidity/comorbidity.component';
import { ComorbidityService } from './services/comorbidity.service';
import { PatientCharacteristicsComponent } from './components/ehr/patients/components/patient-characteristics/patient-characteristics.component';
import { LaboratoryResultSectionComponent } from './components/ehr/patients/components/laboratory-section/laboratory-result-section/laboratory-result-section.component';
import { ReusableDynamicTableComponent } from './common/reusable-dynamic-table/reusable-dynamic-table.component';
import { DynamicService } from './services/dynamic.service';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { ImageCropperComponent } from './components/ehr/register-patient/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HysteroscopySectionComponent } from './components/ehr/patients/components/hysteroscopy-section/hysteroscopy-section.component';
import { HysteroscopyExaminationComponent } from './components/ehr/patients/components/hysteroscopy-section/hysteroscopy-examination/hysteroscopy-examination.component';
import { HysteroscopyFileAddComponent } from './components/ehr/patients/components/hysteroscopy-section/hysteroscopy-file/hysteroscopy-file-add/hysteroscopy-file-add.component';
import { HysteroscopyFileService } from './services/hyseroscopyFile.service';
import { HysteroscopyFileComponent } from './components/ehr/patients/components/hysteroscopy-section/hysteroscopy-file/hysteroscopy-file.component';
import { HysteroscopyStartExaminationComponent } from './components/ehr/patients/components/hysteroscopy-section/hysteroscopy-start-examination/hysteroscopy-start-examination.component';
import { PatientsGocComponent } from './components/ehr/patients-goc/patients-goc.component';
import { CognitiveAssessmentSectionComponent } from './components/ehr/patients/components/cognitive-assessment-section/cognitive-assessment-section.component';
import { SurveyModule } from 'survey-angular-ui';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { EmergencyContactsComponent } from './components/ehr/register-patient/emergency-contacts/emergency-contacts.component';
import { AdmissionComponent } from './components/ehr/admission/admission.component';
import { AdmissionProfileComponent } from './components/ehr/admission/admission-profile/admission-profile.component';
import { AdmissionPersonalInfoComponent } from './components/ehr/admission/admission-profile/admission-personal-info/admission-personal-info.component';
import { AdmissionMedicalInfoComponent } from './components/ehr/admission/admission-profile/admission-medical-info/admission-medical-info.component';
import { AdmissionAddressInfoComponent } from './components/ehr/admission/admission-profile/admission-address-info/admission-address-info.component';
import { AdmissionEmergencyContactsInfoComponent } from './components/ehr/admission/admission-profile/admission-emergency-contacts-info/admission-emergency-contacts-info.component';
import { AdmissionDocumentsComponent } from './components/ehr/admission/admission-profile/admission-documents/admission-documents.component';
import { DocumentComponent } from './components/ehr/admission/admission-profile/admission-documents/document/document.component';
import { AdmissionRejectModalComponent } from './components/ehr/admission/admission-reject-modal/admission-reject-modal.component';
import { AdmissionAdmitModalComponent } from './components/ehr/admission/admission-admit-modal/admission-admit-modal.component';
import { DashboardAmenComponent } from './components/ehr/dashboard-amen/dashboard-amen.component';
import { DashboardInpatientByOlderAgeComponent } from './components/ehr/dashboard-amen/dashboard-inpatient-by-older-age/dashboard-inpatient-by-older-age.component';
import { DashboardMohDmswComponent } from './components/ehr/dashboard-amen/dashboard-moh-dmsw/dashboard-moh-dmsw.component';
import { DashboardAdmissionsMohDmswComponent } from './components/ehr/dashboard-amen/dashboard-admissions-moh-dmsw/dashboard-admissions-moh-dmsw.component';
import { DashboardRegistrationHistoryDateComponent } from './components/ehr/dashboard-amen/dashboard-registration-history-date/dashboard-registration-history-date.component';
import { DashboardRegistrationHistoryMonthComponent } from './components/ehr/dashboard-amen/dashboard-registration-history-month/dashboard-registration-history-month.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { CanLeaveGuard } from './guards/can-leave.guard';
import { NzImageModule } from 'ng-zorro-antd/image';
import { WorkspaceComponent } from './components/ehr/workspace/workspace.component';
import { MyPatientsComponent } from './components/ehr/workspace/my-patients/my-patients.component';
import { WorkspaceService } from './services/workspace.service';
import { DashboardAverageRegistrationStatusTimesComponent } from './components/ehr/dashboard-amen/dashboard-average-registration-status-times/dashboard-average-registration-status-times.component';
import { AdmissionPopupComponent } from './components/ehr/admission/admission-popup/admission-popup.component';
import { MedicalInfoComponent } from './components/ehr/register-patient/medical-info/medical-info.component';
import { AdmissionInsuranceComponent } from './components/ehr/admission/admission-profile/admission-insurance/admission-insurance.component';
import { DashboardAdmissionsByGenderComponent } from './components/ehr/dashboard/dashboard-admissions-by-gender/dashboard-admissions-by-gender.component';
import { DashboardAdmissionsByOlderAgeComponent } from './components/ehr/dashboard-amen/dashboard-admissions-by-older-age/dashboard-admissions-by-older-age.component';
import { TokenService } from './services/token.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MultipleSclerosisSegmentationComponent } from './components/ehr/patients/components/imaging-section/multiple-sclerosis-segmentation/multiple-sclerosis-segmentation.component';
import { NewloginComponent } from './components/newlogin/newlogin.component';
import { NewforgotComponent } from './components/newforgot/newforgot.component';
import { NewresetComponent } from './components/newreset/newreset.component';
import { OurKeycloakService } from './services/keycloak.service';
import { CustomTableComponent } from './components/ehr/workspace/my-patients/custom-table/custom-table.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { IncomingReferralsComponent } from './components/ehr/workspace/incoming-referrals/incoming-referrals.component';
import { CalendarEventsService } from './services/calendarEvents.service';
import { InitialAssessmentAmenSectionComponent } from './components/ehr/patients/components/inital-assessment-amen-section/initial-assessment-amen-section.component';
import { DynamicQuestionnaireComponent } from './components/ehr/patients/components/inital-assessment-amen-section/dynamic-questionnaire/dynamic-questionnaire.component';
import { SocialHistoryQuestonaireComponent } from './components/ehr/patients/components/inital-assessment-amen-section/social-history-questonaire/social-history-questonaire.component';
import { DynamicQuestionsComponent } from './components/ehr/patients/components/inital-assessment-amen-section/dynamic-questionnaire/dynamic-questions/dynamic-questions.component';
import { InitialAssessmentService } from './services/initialAssessment.service';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NotFoundComponent } from './components/ehr/not-found/not-found.component';
import { SupportingDocumentsComponent } from './components/ehr/patients/components/inital-assessment-amen-section/supporting-documents/supporting-documents.component';
import { TooltipComponent } from './common/tooltip/tooltip.component';
import { SupportingDocumentsService } from './services/supportingDocuments.service';
import { SupportingDocumentsAddComponent } from './components/ehr/patients/components/inital-assessment-amen-section/supporting-documents/supporting-documents-add/supporting-documents-add.component';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { SmartHealthLinksComponent } from './components/smart-health-links/smart-health-links.component';
import { SHLService } from './services/shl.service';
import { ShlPatientSummaryComponent } from './components/smart-health-links/shl-patient-summary/shl-patient-summary.component';
import { ExternalDoctorsComponent } from './components/ehr/admission/admission-profile/admission-medical-info/external-doctors/external-doctors.component';
import { RegistrationExternalDoctorsComponent } from './components/ehr/register-patient/medical-info/registration-external-doctors/registration-external-doctors.component';
import { AccommodationService } from './services/accomodation.service';
import { AccountService } from './services/account.service';
import { AssignBedComponent } from './components/ehr/in-patients/assign-bed/assign-bed.component';
import { DischargeBedComponent } from './components/ehr/in-patients/discharge-bed/discharge-bed.component';
import { MoveBedComponent } from './components/ehr/in-patients/move-bed/move-bed.component';

// Define the loader for translations
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Define icon definitions
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    EhrComponent,
    HeaderComponent,
    PatientsComponent,
    InPatientsComponent,
    DashboardComponent,
    RegisterPatientComponent,
    PersonalInfoComponent,
    IdentifiersComponent,
    AddressComponent,
    InsuranceComponent,
    EmergencyContactsComponent,
    RegistrationExternalDoctorsComponent,
    ProfileComponent,
    EpisodeOfCareComponent,
    PatientsHeaderComponent,
    MedicalHistorySectionComponent,
    AllergiesComponent,
    ClinicalExaminationSectionComponent,
    CurrentProblemsComponent,
    ResolvedProblemsComponent,
    TestComponent,
    MedicalDeviceComponent,
    ProcedureComponent,
    EocSectionComponent,
    AlertsSectionComponent,
    MedicalProblemsSectionComponent,
    PreviewSummaryComponent,
    UserManagementComponent,
    UserManagementEditComponent,
    UserManagementNewComponent,
    DetailedProfileComponent,
    VaccinationComponent,
    MedicalAlertComponent,
    MedicalHistoryComponent,
    PregnancySectionComponent,
    PregnancyHistoryComponent,
    PregnancyOutcomeComponent,
    PregnancyStatusComponent,
    ImagingSectionComponent,
    ImagingFilesAddComponent,
    LaboratorySectionComponent,
    LaboratoryFileAddComponent,
    SupportingDocumentsAddComponent,
    SocialHistorySectionComponent,
    SocialHistoryComponent,
    SocialHistoryQuestonaireComponent,
    DynamicQuestionnaireComponent,
    DynamicQuestionsComponent,
    HealthCarePlanSectionComponent,
    CarePlanComponent,
    MedicationSummarySectionComponent,
    MedicationAddComponent,
    CurrentMedicationComponent,
    PastMedicationComponent,
    TravelHistorySectionComponent,
    TravelHistoryComponent,
    MedicationExpandComponent,
    VitalSignsComponent,
    DailyMonitoringSectionComponent,
    VitalSignsChartComponent,
    DashboardInpatientByAgeComponent,
    DashboardInpatientByGenderComponent,
    DashboardInpatientByGenderComponent,
    DashboardBedAvailabilityByWardComponent,
    DashboardBedAvailabilityByDayComponent,
    DashboardAdmissionReasonComponent,
    DashboardAdmissionsAndDischargesComponent,
    DashboardDischargeTypesComponent,
    ArterialBloodGasComponent,
    GlasgowComponent,
    TracheostomySectionComponent,
    CtParametersComponent,
    TubeCharacteristicsComponent,
    EtiologySectionComponent,
    EtiologyComponent,
    ComorbiditySectionComponent,
    PreciousAiComponent,
    AdmissionProfileComponent,
    AdmissionPersonalInfoComponent,
    AdmissionMedicalInfoComponent,
    AdmissionAddressInfoComponent,
    AdmissionEmergencyContactsInfoComponent,
    ExternalDoctorsComponent,
    AssessmentSectionComponent,
    ComplicationSectionComponent,
    ComplicationComponent,
    CapnographySectionComponent,
    CapnographyComponent,
    ComorbidityComponent,
    PatientCharacteristicsComponent,
    LaboratoryResultSectionComponent,
    ImageCropperComponent,
    HysteroscopySectionComponent,
    HysteroscopyExaminationComponent,
    HysteroscopyFileAddComponent,
    HysteroscopyStartExaminationComponent,
    PatientsGocComponent,
    CognitiveAssessmentSectionComponent,
    InitialAssessmentAmenSectionComponent,
    AdmissionComponent,
    AdmissionDocumentsComponent,
    DocumentComponent,
    AdmissionRejectModalComponent,
    AdmissionAdmitModalComponent,
    DashboardAmenComponent,
    DashboardInpatientByOlderAgeComponent,
    DashboardMohDmswComponent,
    DashboardAdmissionsMohDmswComponent,
    DashboardRegistrationHistoryDateComponent,
    DashboardRegistrationHistoryMonthComponent,
    DashboardAverageRegistrationStatusTimesComponent,
    WorkspaceComponent,
    MyPatientsComponent,
    AdmissionPopupComponent,
    MedicalInfoComponent,
    AdmissionInsuranceComponent,
    DashboardAdmissionsByGenderComponent,
    DashboardAdmissionsMohDmswComponent,
    DashboardAdmissionsByOlderAgeComponent,
    NewloginComponent,
    NewforgotComponent,
    NewresetComponent,
    IncomingReferralsComponent,
    NotFoundComponent,
    ForgotUsernameComponent,
    SmartHealthLinksComponent,
    ShlPatientSummaryComponent,
    AssignBedComponent,
    DischargeBedComponent,
    MoveBedComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    MatProgressSpinnerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatSelectModule,
    MatButtonModule,
    BrowserModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonToggleModule,
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatExpansionModule,
    MatStepperModule,
    NzMenuModule,
    NzLayoutModule,
    StoreModule.forRoot({ menu: menuReducer }),
    NzTableModule,
    NzButtonModule,
    NzSwitchModule,
    NzModalModule,
    NzToolTipModule,
    NzIconModule,
    NzSpinModule,
    NzSelectModule,
    BrowserModule,
    PdfViewerModule,
    FileSaverModule,
    NzUploadModule,
    NzRadioModule,
    NzProgressModule,
    NzDatePickerModule,
    NzNoAnimationModule,
    NzTabsModule,
    NzCheckboxModule,
    ImagingFilesComponent,
    LaboratoryFileComponent,
    SupportingDocumentsComponent,
    HysteroscopyFileComponent,
    ReusableTableComponent,
    ReusableReverseTableComponent,
    ReusableDynamicTableComponent,
    NzAvatarModule,
    ImageCropperModule,
    SurveyModule,
    NzStepsModule,
    NzImageModule,
    NgxIntlTelInputModule,
    MultipleSclerosisSegmentationComponent,
    CustomTableComponent,
    FullCalendarModule,
    NzInputModule,
    TooltipComponent,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useFactory: provideNzI18n,
      deps: [LocaleService], // Add LocaleService as a dependency
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl,
      deps: [TranslateService],
    },
    AuthGuard,
    MenuService,
    PatientService,
    AddPatientService,
    LocaleService,
    UserService,
    AllergyService,
    CurrentProblemsService,
    EpisodeOfCareService,
    CurrentProblemsService,
    ProcedureService,
    UserManagementService,
    VaccinationService,
    MedicalAlertService,
    MedicalHistoryService,
    MedicalDeviceService,
    CurrentProblemsService,
    ResolvedProblemsService,
    PregnancyHistoryService,
    PregnancyOutcomeService,
    PregnancyStatusService,
    ImagingService,
    LaboratoryFileService,
    SupportingDocumentsService,
    HysteroscopyFileService,
    DynamicService,
    SocialHistoryService,
    InitialAssessmentService,
    CarePlanService,
    CurrentMedicationService,
    PastMedicationService,
    PharmacyService,
    TravelHistoryService,
    VitalSignService,
    DischargeService,
    ArterialBloodGasService,
    GlasgowService,
    TenantSettingsService,
    CtParametersService,
    TubeCharacteristicsService,
    EtiologyService,
    ComplicationService,
    CapnographyService,
    ComorbidityService,
    CanLeaveGuard,
    WorkspaceService,
    TokenService,
    OurKeycloakService,
    CalendarEventsService,
    SHLService,
    AccommodationService,
    AccountService,
    TenantSettingsService,
    { provide: NZ_ICONS, useValue: icons },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

function provideNzI18n(localeService: LocaleService) {
  const currentLocale = localeService.getCurrentLocale();
  return currentLocale === 'el' ? el_GR : en_US;
}
