import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { IAddAccommodation } from '../interfaces/Accommodation/IAddAccommodation';

@Injectable()
export class AccommodationService {
  constructor(private readonly _http: HttpClient) {}

  moveBed(accommodationObj: IAddAccommodation) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}AccommodationData/MoveBed`,
      accommodationObj,
      {
        headers: headers,
      }
    );
  }
}
