import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { IGetList } from '../interfaces/Get/IGetList';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IProcedureDetailed } from '../interfaces/Procedure/IProcedureDetailed';
import { IProcedureDropdown } from '../interfaces/Procedure/IProcedureDropdown';
import { IAddProcedure } from '../interfaces/Procedure/IAddProcedure';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProcedureService {
  public procedureModalSub = new BehaviorSubject<boolean>(false);

  public procedureEditSub = new BehaviorSubject<IProcedureDetailed | undefined>(
    undefined
  );

  public procedureDataSub = new BehaviorSubject<IGetList<IProcedureDetailed>>(
    {}
  );

  public procedureLoadingSub = new BehaviorSubject<boolean>(false);
  public procedureLoadingSub$ = this.procedureLoadingSub.asObservable();

  public procedureNoKnownLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.procedureLoadingSub.next(true);
    await this.getProcedures(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.procedureDataSub.next(response?.data!);
        this.procedureLoadingSub.next(false);
        this.procedureNoKnownLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant('Unable to retrieve procedures')}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching procedures:', err);
      },
    });
  }

  getProcedures(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IGetList<IProcedureDetailed>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IGetList<IProcedureDetailed>>
      >(`${environment.BACKEND_URL}Procedure/${patientId}?visitId=${visitId}`, {
        headers: headers,
      });
    } else {
      return this._http.get<
        IGenericServiceResponse<IGetList<IProcedureDetailed>>
      >(`${environment.BACKEND_URL}Procedure/${patientId}`, {
        headers: headers,
      });
    }
  }

  getProcedureDropdown(): Observable<
    IGenericServiceResponse<IProcedureDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IProcedureDropdown>>(
      `${environment.BACKEND_URL}Procedure/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addProcedure(procedureObj: IAddProcedure) {
    procedureObj.visitId = this.eocService.currentVisitId();
    procedureObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Procedure`,
      procedureObj,
      {
        headers: headers,
      }
    );
  }

  addNoKnownProcedure() {
    const object = {
      patientId: this.patientService.patientIDsignal(),
      visitId: this.eocService.currentVisitId(),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Procedure/NoKnownProcedures`,
      object,
      {
        headers: headers,
      }
    );
  }

  editProcedure(procedureObj: IAddProcedure) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Procedure/${procedureObj.id}`,
      procedureObj,
      {
        headers: headers,
      }
    );
  }

  deleteProcedure(procedureId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Procedure/${procedureId}`,
      {
        headers: headers,
      }
    );
  }

  deleteNoKnownProcedure(procedureId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Procedure/NoKnownProcedures/${procedureId}`,
      {
        headers: headers,
      }
    );
  }
}
