import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { ICoding, ICodingSpecialties } from 'src/app/interfaces/ICoding';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import Swal from 'sweetalert2';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IGetExternalDoctor } from 'src/app/interfaces/ExternalDoctors/IGetExternalDoctor';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-external-doctors',
  templateUrl: './registration-external-doctors.component.html',
  styleUrls: ['./registration-external-doctors.component.css'],
})
export class RegistrationExternalDoctorsComponent {
  @Input() externalDoctorsDropdown: NzSelectOptionInterface[] = [];
  @Input() externalDoctorsApi: IGetExternalDoctor[] = [];
  @Input() countriesList: ICoding[] = [];
  @Input() doctorSpecialty1: ICodingSpecialties[] = [];
  @Input() doctorSpecialty2: ICodingSpecialties[] = [];
  @Input() doctorSpecialty3: ICodingSpecialties[] = [];
  @Input() formSubmitted?: boolean = false;

  externalDoctorsForm: FormGroup;
  isCountryCyprus: boolean = false;
  allSpecialties: ICodingSpecialties[] = [];

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];

  private isUpdating = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService
  ) {
    this.externalDoctorsForm = this.formBuilder.group({
      externalDoctors: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.loadAllSpecialties();
    // this.addNewDoctor();
  }

  loadAllSpecialties() {
    this.allSpecialties = [
      ...this.doctorSpecialty1,
      ...this.doctorSpecialty2,
      ...this.doctorSpecialty3,
    ];
  }

  get externalDoctors(): FormArray {
    return this.externalDoctorsForm.get('externalDoctors') as FormArray;
  }

  createDoctorFormGroup(): FormGroup {
    return this.formBuilder.group({
      doctorId: [null, Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      registrationNumber: ['', Validators.required],
      addressData: this.formBuilder.group({
        street: [''],
        streetNumber: [''],
        apartmentNumber: [''],
        town: [''],
        postCode: [''],
        district: [''],
        countryId: [null], // Use null to match patching
      }),
      specialties: [[]], // Array for specialty IDs
      toggleControl: [false],
    });
  }

  addNewDoctor() {
    const newDoctorForm = this.createDoctorFormGroup();
    this.externalDoctors.push(newDoctorForm);
  }

  removeDoctor(index: number) {
    this.externalDoctors.removeAt(index);
  }

  onCountryChange(placeId: number): void {
    this.isCountryCyprus = placeId === 55; // Cyprus id is 55
  }

  onExternalDoctorToggle(isExternal: boolean, index: number) {
    const doctorGroup = this.externalDoctors.at(index) as FormGroup;

    if (!isExternal) {
      // Enable all controls for manual input
      doctorGroup.enable();
    } else {
      // Disable specific controls for external doctor selection
      doctorGroup.get('specialties')?.disable();
      doctorGroup.get('firstName')?.disable();
      doctorGroup.get('lastName')?.disable();
      doctorGroup.get('phoneNumber')?.disable();
      doctorGroup.get('registrationNumber')?.disable();

      const addressGroup = doctorGroup.get('addressData') as FormGroup;
      if (addressGroup) {
        addressGroup.disable();
      }
    }
  }

  onDoctorSelectionChange(doctorId: number, index: number) {
    // Prevent recursive calls
    if (this.isUpdating) return;

    this.isUpdating = true;

    const existingIds = this.externalDoctors.controls
      .map((control, idx) =>
        idx !== index ? control.get('doctorId')?.value : null
      )
      .filter((id) => id !== null);

    if (existingIds.includes(doctorId)) {
      // Reset the current dropdown selection and show an error message
      this.externalDoctors.at(index).reset();
      Swal.fire({
        icon: 'error',
        title: `${this.translate.instant('Duplicate Doctor')}`,
        text: `${this.translate.instant(
          'This doctor is already added in the form.'
        )}`,
        confirmButtonText: 'OK',
        confirmButtonColor: '#d33',
      });
      this.isUpdating = false;
      return;
    }

    // Find the selected doctor data from the API list
    const doctorData = this.externalDoctorsApi.find(
      (doc) => doc.id === doctorId
    );

    if (doctorData) {
      const specialtyIds = [
        doctorData.specialty1,
        doctorData.specialty2,
        doctorData.specialty3,
      ].filter((id) => id);

      // Get the current form data for merging
      const currentFormData = this.externalDoctors.at(index).value;

      // Ensure addressData exists to prevent undefined errors
      const currentAddressData = currentFormData.addressData || {};

      // Merge the selected doctor's data with any existing manual input
      const updatedDoctor = {
        doctorId: doctorData.id,
        firstName: doctorData.firstName || currentFormData.firstName || '',
        lastName: doctorData.lastName || currentFormData.lastName || '',
        phoneNumber:
          doctorData.phoneNumber || currentFormData.phoneNumber || '',
        registrationNumber:
          doctorData.registrationNumber ||
          currentFormData.registrationNumber ||
          '',
        addressData: {
          street:
            `${doctorData.address1 ?? ''} ${
              doctorData.address2 ?? ''
            }`.trim() ||
            currentAddressData.street ||
            '',
          town: doctorData.city || currentAddressData.town || '',
          postCode: doctorData.postalCode || currentAddressData.postCode || '',
          district: currentAddressData.district || '', // Use default value if undefined
          streetNumber: currentAddressData.streetNumber || '',
          apartmentNumber: currentAddressData.apartmentNumber || '',
          countryId:
            doctorData.country?.toLowerCase() === 'cy' ||
            doctorData.country?.toLowerCase() === 'cyprus'
              ? 55
              : currentAddressData.countryId || null,
        },
        specialties:
          specialtyIds.length > 0
            ? specialtyIds
            : currentFormData.specialties || [],
        toggleControl: true, // Indicates the doctor is selected from the dropdown
      };
      console.log('FormGroup Structure:', this.externalDoctors.at(index));

      // Log the updated doctor data
      console.log('Updated Doctor Data:', updatedDoctor);

      // Patch the form with the updated doctor data
      this.externalDoctors
        .at(index)
        .patchValue(updatedDoctor, { emitEvent: false });

      console.log(
        'Patched Doctor Data:',
        this.externalDoctors.at(index).getRawValue()
      );
    }

    this.isUpdating = false;
  }

  enter(event: any) {
    event.preventDefault();

    const nextButton = document.getElementById(
      'emergency-next-btn'
    ) as HTMLButtonElement;
    if (nextButton) {
      nextButton.click();
    } else {
      console.warn('Button not found.');
    }
  }
}
