import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IAddCapnography } from '../interfaces/Capnography/IAddCapnography';
import { environment } from 'src/environments/environment';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import Swal from 'sweetalert2';
import { ICapnographyDetailed } from '../interfaces/Capnography/ICapnographyDetailed';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CapnographyService {
  public capnographyModalSub = new BehaviorSubject<boolean>(false);

  public capnographyEditSub = new BehaviorSubject<IAddCapnography | undefined>(
    undefined
  );

  public capnographyDataSub = new BehaviorSubject<ICapnographyDetailed[]>([]);

  public capnographyLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.capnographyLoadingSub.next(true);
    this.getCapnographyData(
      +this.patientService.patientIDsignal(),
      +this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.capnographyDataSub.next(response?.data);
        this.capnographyLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Unable to retrieve capnography data'
          )}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching capnography data:', err);
      },
    });
  }

  getCapnographyData(
    patientId: number,
    visitId?: number
  ): Observable<IGenericServiceResponse<ICapnographyDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<ICapnographyDetailed[]>>(
        `${environment.BACKEND_URL}Capnography/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<ICapnographyDetailed[]>>(
        `${environment.BACKEND_URL}Capnography/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  addCapnography(capnographyObj: IAddCapnography) {
    capnographyObj.visitId = +this.eocService.currentVisitId();
    capnographyObj.patientId = +this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Capnography`,
      capnographyObj,
      {
        headers: headers,
      }
    );
  }

  editCapnography(capnographyObj: IAddCapnography) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Capnography/${capnographyObj.id}`,
      capnographyObj,
      {
        headers: headers,
      }
    );
  }

  deleteCapnography(capnographyId: number): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Capnography/${capnographyId}`,
      {
        headers: headers,
      }
    );
  }

  deleteCapnographyFromTable(capnographyId: string) {
    // REMOVE THE Laboratory Result from the table
    this.capnographyDataSub.next(
      this.capnographyDataSub.value.filter(
        (ma) => ma.id?.toString() != capnographyId
      )
    );
  }
}
