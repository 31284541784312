<form
  [formGroup]="personalInfoForm"
  [ngClass]="{ submitted: formSubmitted }"
  (keydown.enter)="enter($event)"
>
  <div>
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="100"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Upload Profile Picture" | translate
        }}<span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div
        fxLayout="col"
        fxFlex="100"
        class="add-patient-profile"
        fxLayoutAlign="start center"
      >
        <div style="margin-right: 20px">
          <nz-avatar
            [nzShape]="'square'"
            [nzSize]="100"
            [nzIcon]="'user'"
            [nzSrc]="avatarImageUrl"
          ></nz-avatar>
        </div>
        <button
          nz-button
          nzType="primary"
          class="upload-patient-picture-btn"
          (click)="uploadAndOpenImageCropper()"
        >
          <i nz-icon nzType="download"></i>
          {{ "Upload Profile Picture" | translate }}
        </button>
      </div>
    </div>

    <!-- First Name and Last Name -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "First name" | translate }}
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Last name" | translate }}
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'First name' | translate"
          formControlName="firstName"
        />
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'Last name' | translate"
          formControlName="lastName"
        />
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Gender" | translate }}
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Date of birth" | translate }}
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <nz-select
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          class="add-patient-inputZorro"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Gender' | translate"
          formControlName="gender"
        >
          <ng-container *ngFor="let gender of gendersList">
            <nz-option
              nzLabel="{{ gender.name }}"
              nzValue="{{ gender.id }}"
            ></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          formControlName="dateOfBirth"
          class="add-patient-input"
          type="date"
        />
      </div>
    </div>

    <!-- Date of Birth and Place of Birth -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Place of Birth" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "District of Birth" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <!-- Place of Birth Selector -->
        <nz-select
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          class="add-patient-inputZorro"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Place of Birth' | translate"
          formControlName="placeOfBirthId"
          (ngModelChange)="onPlaceOfBirthChange($event)"
        >
          <ng-container *ngFor="let place of placeOfBirthList">
            <nz-option [nzLabel]="place.name" [nzValue]="place.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <!-- Conditional rendering for District of Birth -->
        <ng-container *ngIf="!isPlaceOfBirthCyprus; else cyprusDropdown">
          <!-- Text input if not Cyprus -->
          <input
            class="add-patient-input"
            [placeholder]="'District of Birth' | translate"
            formControlName="districtOfBirth"
            [maxLength]="50"
          />
        </ng-container>
        <!-- Dropdown if Cyprus -->
        <ng-template #cyprusDropdown>
          <nz-select
            (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
            class="add-patient-inputZorro"
            nzShowSearch
            nzAllowClear
            [nzPlaceHolder]="'Select a District' | translate"
            formControlName="districtOfBirth"
          >
            <ng-container *ngFor="let city of cyprusCities">
              <nz-option
                [nzLabel]="city | translate"
                [nzValue]="city"
              ></nz-option>
            </ng-container>
          </nz-select>
        </ng-template>
      </div>
    </div>

    <!-- Phone Number and Email Address -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Phone Number" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      ></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <ngx-intl-tel-input
          [cssClass]="'custom'"
          [preferredCountries]="[CountryISO.Cyprus, CountryISO.Greece]"
          [enableAutoCountrySelect]="false"
          [enablePlaceholder]="false"
          [searchCountryFlag]="true"
          [searchCountryField]="[
            SearchCountryField.Iso2,
            SearchCountryField.Name
          ]"
          [selectFirstCountry]="false"
          [selectedCountryISO]="CountryISO.Cyprus"
          [maxLength]="15"
          [phoneValidation]="true"
          [separateDialCode]="true"
          name="phone"
          formControlName="phone"
        ></ngx-intl-tel-input>
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col"></div>
    </div>

    <!--  Religion -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Religion" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      ></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <nz-select
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          class="add-patient-inputZorro"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Religion' | translate"
          formControlName="religionId"
        >
          <ng-container *ngFor="let religion of religionList">
            <nz-option
              nzLabel="{{ religion.name }}"
              nzValue="{{ religion.id }}"
            ></nz-option>
          </ng-container>
        </nz-select>
        <!-- 
            Conditionally show the free text input. 
            The value 26 is the  id of option "Other" in religion
            -->
        <input
          *ngIf="personalInfoForm.get('religionId')?.value == 26"
          class="add-patient-input"
          formControlName="religionOther"
          placeholder="{{ 'Please specify' | translate }}"
          [maxLength]="50"
        />
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col"></div>
    </div>

    <!-- Education Level and Occupation -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Education Level" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Occupation" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <nz-select
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          class="add-patient-inputZorro"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Education Level' | translate"
          formControlName="educationLevelId"
        >
          <ng-container *ngFor="let education of educationLevelList">
            <nz-option
              nzLabel="{{ education.name }}"
              nzValue="{{ education.id }}"
            ></nz-option>
          </ng-container>
        </nz-select>
        <!-- 
            Conditionally show the free text input. 
            The value 5 is the  id of option "Other" in education level
            -->
        <input
          *ngIf="personalInfoForm.get('educationLevelId')?.value == 5"
          class="add-patient-input"
          formControlName="educationLevelOther"
          placeholder="{{ 'Please specify' | translate }}"
          [maxLength]="50"
        />
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'Occupation' | translate"
          formControlName="occupation"
          [maxLength]="100"
        />
      </div>
    </div>

    <!-- Family Status and Closest Relatives -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Family Status" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Closest Relatives" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <nz-select
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          class="add-patient-inputZorro"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Family Status' | translate"
          formControlName="familyStatusId"
        >
          <ng-container *ngFor="let status of familyStatusList">
            <nz-option
              nzLabel="{{ status.name }}"
              nzValue="{{ status.id }}"
            ></nz-option>
          </ng-container>
        </nz-select>
        <!-- 
            Conditionally show the free text input. 
            The value 6 is the  id of option "Other" in family status
            -->
        <input
          *ngIf="personalInfoForm.get('familyStatusId')?.value == 6"
          class="add-patient-input"
          formControlName="familyStatusOther"
          placeholder="{{ 'Please specify' | translate }}"
          [maxLength]="50"
        />
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <nz-select
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          class="add-patient-inputZorro"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Closest Relatives' | translate"
          formControlName="closestRelativesId"
        >
          <ng-container *ngFor="let relative of closestRelativesList">
            <nz-option
              nzLabel="{{ relative.name }}"
              nzValue="{{ relative.id }}"
            ></nz-option>
          </ng-container>
        </nz-select>
        <!-- 
            Conditionally show the free text input. 
            The value 7 is the  id of option "Other" in closest relatives
            -->
        <input
          *ngIf="personalInfoForm.get('closestRelativesId')?.value == 7"
          class="add-patient-input"
          formControlName="closestRelativesOther"
          placeholder="{{ 'Please specify' | translate }}"
          [maxLength]="50"
        />
      </div>
    </div>

    <!-- Amount of Income and Source of Income -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Amount of Income" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div
        fxLayout="col"
        fxFlex="50"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Source of Income" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'Amount of Income' | translate"
          formControlName="amountOfIncome"
          [maxLength]="50"
        />
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <nz-select
          (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
          class="add-patient-inputZorro"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'Source of Income' | translate"
          formControlName="sourceOfIncomeId"
        >
          <ng-container *ngFor="let source of sourceOfIncomeList">
            <nz-option
              nzLabel="{{ source.name }}"
              nzValue="{{ source.id }}"
            ></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>

    <!-- Date of Admission, Registration Agent  -->
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div
        fxLayout="col"
        fxFlex="33"
        class="add-patient-col"
        fxLayoutAlign="start center"
      >
        {{ "Date of Admission" | translate
        }}<span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div
        fxLayout="col"
        fxFlex="33"
        class="add-patient-col"
        fxLayoutAlign="start center"
      ></div>
      <div
        fxLayout="col"
        fxFlex="33"
        class="add-patient-col"
        fxLayoutAlign="start center"
      ></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="33" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'Date of Admission' | translate"
          formControlName="dateOfAdmission"
          type="date"
        />
      </div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col"></div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col"></div>
    </div>
  </div>
</form>
