import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IArterialBloodGasDetailed } from 'src/app/interfaces/ArterialBloodGas/IArterialBloodGasDetailed';
import { ArterialBloodGasService } from 'src/app/services/arterialBloodGas.service';
import Swal from 'sweetalert2';
import { ArterialBloodGasAddEditComponent } from './arterial-blood-gas-add-edit/arterial-blood-gas-add-edit.component';
import { abgRV } from 'src/app/interfaces/IReferenceValue';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-arterial-blood-gas',
  templateUrl: './arterial-blood-gas.component.html',
  styleUrls: ['./arterial-blood-gas.component.css'],
})
export class ArterialBloodGasComponent {
  public addComp = ArterialBloodGasAddEditComponent;

  private destroy$ = new Subject<void>();

  arterialBloodGases?: IArterialBloodGasDetailed[];
  isLoading: boolean = false;

  referenceValues = abgRV;

  @Input() removeMargin: boolean = true;

  constructor(
    private readonly arterialBloodGasService: ArterialBloodGasService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: IArterialBloodGasDetailed) {
    this.arterialBloodGasService.arterialBloodGasModalSub.next(true);
    this.arterialBloodGasService.arterialBloodGasEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Arterial Blood Gas Record?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.arterialBloodGasService
          .deleteArterialBloodGas(id.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Arterial Blood Gas deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.arterialBloodGasService.deleteArterialBloodGasFromTable(
                id.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to delete Arterial Blood Gas record'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant(
            'Arterial Blood Gas record is not deleted'
          )}`,
          '',
          'info'
        );
      }
    });
  }
  ngOnInit() {
    this.arterialBloodGasService.arterialBloodGasLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.arterialBloodGasService.arterialBloodGasDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IArterialBloodGasDetailed[]) => {
        this.arterialBloodGases = data;
      });

    this.arterialBloodGasService.getData();
  }
}
