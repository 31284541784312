import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  signal,
  ViewChild,
} from '@angular/core';
import {
  Calendar,
  CalendarApi,
  CalendarOptions,
  DateSelectArg,
  EventApi,
  EventClickArg,
} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

import {
  DEPARTMENT_LIST,
  INITIAL_EVENTS,
  PATIENTS_LIST,
  PERSONNEL_LIST,
  createEventId,
} from './event-utils';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarEventsService } from 'src/app/services/calendarEvents.service';
import { AddCalendarEventComponent } from './add-calendar-event/add-calendar-event.component';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { Subscription } from 'rxjs';
import { FullCalendarComponent } from '@fullcalendar/angular';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incoming-referrals',
  templateUrl: './incoming-referrals.component.html',
  styleUrl: './incoming-referrals.component.css',
})
export class IncomingReferralsComponent {
  @ViewChild('calendar') calendarElement!: FullCalendarComponent;
  public addCalentarEventComp = AddCalendarEventComponent;
  calendarVisible = signal(true);
  calendarOptions = signal<CalendarOptions>({
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    dayMinWidth: 150, // will cause horizontal scrollbars

    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      resourceTimelinePlugin,
      resourceTimeGridPlugin,
      scrollGridPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today addEventButton',
      center: 'title',
      right:
        'dayGridMonth,timeGridWeek,timeGridDay,listWeek,resourceTimeline,resourceTimeGridFourDay',
    },
    customButtons: {
      addEventButton: {
        text: 'Add Event',
        click: () => this.openModalFun(true), // Custom button handler
      },
    },
    resources: PATIENTS_LIST,
    views: {
      resourceTimeGridFourDay: {
        type: 'resourceTimeGrid', // Base the view on resourceTimeGrid
        duration: { days: 2 }, // Custom duration of 4 days
        buttonText: '4 day', // Button label in the toolbar
      },
    },
    initialView: 'resourceTimeGridFourDay',
    initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: false,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    // update a remote database when these fire:
    eventAdd: this.handleEventAdd.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventRemove: this.handleEventRemove.bind(this),
  });
  currentEvents = signal<EventApi[]>([]);
  eventSub!: Subscription;
  calendarApi!: Calendar;
  addEventForm!: FormGroup;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private modal: NzModalService,
    private fb: FormBuilder,
    private calendarEventService: CalendarEventsService,
    private vaccinationService: VaccinationService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.addEventForm = this.fb.group({
      title: ['', [Validators.required]],
      start: [null, [Validators.required]],
      end: [null],
      allDay: [false],
    });
    this.eventSub = this.calendarEventService.event$.subscribe((newEvent) => {
      if (newEvent) {
        this.addEventToCalendar(newEvent);
      }
    });
  }

  ngAfterViewInit() {
    this.calendarApi = this.calendarElement.getApi();
  }

  ngOnDestroy() {
    // Clean up the subscription
    this.eventSub.unsubscribe();
  }

  // Format date helper function
  formatDate(date: Date): string {
    const pad = (n: number) => (n < 10 ? '0' + n : n);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`; // Return in YYYY-MM-DDTHH:mm format
  }

  openModalFun(showModal: boolean, start?: Date, end?: Date) {
    const resources = this.calendarOptions().resources || [];
    this.calendarEventService.addCalendarEventModalSub.next({
      showModal: showModal,
      start: start,
      end: end,
      patients: resources,
      personnel: PERSONNEL_LIST,
      departments: DEPARTMENT_LIST,
    });
  }

  openEditModal(event: EventApi) {
    const resources = this.calendarOptions().resources || [];

    // Emit the event data to the service
    this.calendarEventService.addCalendarEventModalSub.next({
      showModal: true,
      event: event,
      calendarApi: this.calendarApi,
      patients: resources,
      personnel: PERSONNEL_LIST,
      departments: DEPARTMENT_LIST,
    });
  }

  // Add the new event to the calendar
  addEventToCalendar(event: any) {
    this.calendarApi.addEvent(event);
  }

  handleCalendarToggle() {
    this.calendarVisible.update((bool) => !bool);
  }

  handleWeekendsToggle() {
    this.calendarOptions.update((options) => ({
      ...options,
      weekends: !options.weekends,
    }));
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.openModalFun(true, selectInfo.start, selectInfo.end);
  }

  handleEventClick(clickInfo: EventClickArg) {
    const event: EventApi = clickInfo.event;
    const title = event.title || 'Not set';
    const start = event.start ? event.start.toLocaleString() : 'Not set';
    const end = event.end ? event.end.toLocaleString() : 'Not set';

    // Extract extendedProps
    const description = event.extendedProps?.['description'] || 'Not set';

    // Handle personnel
    const personnel = Array.isArray(event.extendedProps?.['personnel'])
      ? event.extendedProps['personnel']
          .map((member: string) => `<li>${member}</li>`)
          .join('')
      : '<li>Not set</li>';

    // Handle department
    const department = event.extendedProps?.['department'] || 'Not set';

    // Handle patient
    const patients = Array.isArray(event.extendedProps?.['patients'])
      ? event.extendedProps['patients']
          .map((p: string) => `<li>${p}</li>`)
          .join('')
      : `<li>${event.extendedProps?.['patients'] || 'Not set'}</li>`;

    // Create the modal with the updated information
    this.modal.closeAll();
    this.modal.create({
      nzTitle: 'Event Details',
      nzContent: `
        <div>
          <p><strong>Title:</strong> ${title}</p>
          <p><strong>Start:</strong> ${start}</p>
          <p><strong>End:</strong> ${end}</p>
          <p><strong>Members:</strong></p>
          <ul>${personnel}</ul>
          <p><strong>Department:</strong> ${department}</p>
          <p><strong>Patient(s):</strong></p>
          <ul>${patients}</ul>
          <p><strong>Description:</strong> ${description}</p>
        </div>
      `,
      nzFooter: [
        {
          label: 'Delete',
          danger: true,
          onClick: () => {
            // SweetAlert confirmation
            Swal.fire({
              title: `${this.translate.instant('Are you sure')}?`,
              text: `You are about to delete the event '${title}'.`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#3085d6',
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                // Remove the event

                clickInfo.event.remove();
                this.modal.closeAll();
                Swal.fire(
                  `${this.translate.instant('Deleted')}!`,
                  `${this.translate.instant('Your event has been deleted')}.`,
                  'success'
                );
              }
            });
          },
        },
        {
          label: 'Edit',
          onClick: () => {
            this.modal.closeAll(); // Close the current modal
            this.openEditModal(event); // Open the edit modal
          },
        },
      ],
      nzZIndex: 2000,
    });
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents.set(events);
    this.changeDetector.detectChanges(); // workaround for pressionChangedAfterItHasBeenCheckedError
  }

  handleEventAdd(addedItem: any) {
    const event = addedItem.event;

    // Log event details
    // console.log('Event added:');
    // console.log('Event ID:', event.id);
    // console.log('Title:', event.title);
    // console.log('Start:', this.formatDate(event.start));
    // console.log('End:', event.end ? this.formatDate(event.end) : 'Not set');
    // console.log('All Day:', event.allDay);

    // Print extendedProps if available
    // if (event.extendedProps) {
    //   console.log('Extended Props:', event.extendedProps);
    // }
  }
  handleEventRemove(removedItem: any) {
    const event = removedItem.event;

    // Log event details
    // console.log('Event removed:');
    // console.log('Event ID:', event.id);
    // console.log('Title:', event.title);
    // console.log('Start:', this.formatDate(event.start));
    // console.log('End:', event.end ? this.formatDate(event.end) : 'Not set');
    // console.log('All Day:', event.allDay);

    // Print extendedProps if available
    // if (event.extendedProps) {
    //   console.log('Extended Props:', event.extendedProps);
    // }
  }

  handleEventChange(changedItem: any) {
    const event = changedItem.event;
    // Log event details
    // console.log('Event changed:');
    // console.log('Event ID:', event.id);
    // console.log('Title:', event.title);
    // console.log('Start:', this.formatDate(event.start));
    // console.log('End:', event.end ? this.formatDate(event.end) : 'Not set');
    // console.log('All Day:', event.allDay);

    // Print extendedProps if available
    // if (event.extendedProps) {
    //   console.log('Extended Props:', event.extendedProps);
    // }
  }
}
