import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { ITravelHistoryDetailed } from '../interfaces/TravelHistory/ITravelHistoryDetailed';
import { ITravelHistoryDropdown } from '../interfaces/TravelHistory/ITravelHistoryDropdown';
import { IAddTravelHistory } from '../interfaces/TravelHistory/IAddTravelHistory';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TravelHistoryService {
  public travelHistoryModalSub = new BehaviorSubject<boolean>(false);

  public travelHistoryEditSub = new BehaviorSubject<
    ITravelHistoryDetailed | undefined
  >(undefined);

  public travelHistoryDataSub = new BehaviorSubject<ITravelHistoryDetailed[]>(
    []
  );

  public travelHistoryLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.travelHistoryLoadingSub.next(true);
    this.getTravelHistory(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.travelHistoryDataSub.next(response?.data!);
        this.travelHistoryLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Unable to retrieve travel history'
          )}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching travel history:', err);
      },
    });
  }

  getTravelHistory(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<ITravelHistoryDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<ITravelHistoryDetailed[]>>(
        `${environment.BACKEND_URL}TravelHistory/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<ITravelHistoryDetailed[]>>(
        `${environment.BACKEND_URL}TravelHistory/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  getTravelHistoryDropdown(): Observable<
    IGenericServiceResponse<ITravelHistoryDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<ITravelHistoryDropdown>>(
      `${environment.BACKEND_URL}TravelHistory/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addTravelHistory(travelHistoryObj: IAddTravelHistory) {
    travelHistoryObj.visitId = this.eocService.currentVisitId();
    travelHistoryObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}TravelHistory`,
      travelHistoryObj,
      {
        headers: headers,
      }
    );
  }

  editTravelHistory(travelHistoryObj: IAddTravelHistory) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}TravelHistory/${travelHistoryObj.id}`,
      travelHistoryObj,
      {
        headers: headers,
      }
    );
  }

  deleteTravelHistory(travelHistoryId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}TravelHistory/${travelHistoryId}`,
      {
        headers: headers,
      }
    );
  }

  deleteTravelHistoryFromTable(travelHistoryId: string) {
    // REMOVE THE Vaccination
    this.travelHistoryDataSub.next(
      this.travelHistoryDataSub.value.filter(
        (v) => v.id?.toString() != travelHistoryId
      )
    );
  }
}
