import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { IAddMedicalInfo } from '../../../../../../../interfaces/MedicalInfo/IAddMedicalInfo';
import { MedicalHistoryService } from '../../../../../../../services/medicalHistory.service';

@Component({
  standalone: true,
  selector: 'app-medical-history-add-edit',
  templateUrl: './medical-history-add-edit.component.html',
  styleUrls: ['./medical-history-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class MedicalHistoryAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editMedicalHistoryId?: number;

  medicalHistorySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  medicalHistoryForm: FormGroup = new FormGroup({});

  constructor(
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = false;

    this.medicalHistoryForm = this.formBuilder.group({
      descriptionValue: [null, Validators.required],
    });

    this.medicalHistoryService.medicalHistoryEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editMedicalHistoryId = data?.id;

          this.medicalHistoryForm.patchValue({
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editMedicalHistoryId = undefined;

          this?.medicalHistoryForm.reset();
        }
      });

    this.medicalHistoryService.medicalHistoryModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.medicalHistoryForm.reset();

    this.medicalHistoryService.medicalHistoryModalSub.next(false);

    this.medicalHistorySubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.medicalHistorySubmitted = true;

    if (this.medicalHistoryForm.valid) {
      const medicalHistoryObj: IAddMedicalInfo = {};
      medicalHistoryObj.id = this.editMedicalHistoryId;
      medicalHistoryObj.description =
        this.medicalHistoryForm.value?.descriptionValue;

      if (this.isEdit) {
        this.medicalHistoryService
          .editMedicalHistory(medicalHistoryObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Medical History edited successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.medicalHistoryService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to edit Medical History'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      } else {
        this.medicalHistoryService
          .addMedicalHistory(medicalHistoryObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Medical History added successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.medicalHistoryService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to add Medical History'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      }
    } else {
      this.isLoading = false;
    }
  }
}
