import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IVitalSignDetailed } from '../interfaces/VitalSign/IVitalSignDetailed';
import { IAddVitalSign } from '../interfaces/VitalSign/IAddVitalSign';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class VitalSignService {
  public vitalSignModalSub = new BehaviorSubject<boolean>(false);

  public vitalSignEditSub = new BehaviorSubject<IVitalSignDetailed | undefined>(
    undefined
  );

  public vitalSignDataSub = new BehaviorSubject<IVitalSignDetailed[]>([]);

  public vitalSignLoadingSub = new BehaviorSubject<boolean>(false);
  public vitalSignLoadingSub$ = this.vitalSignLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.vitalSignLoadingSub.next(true);
    this.getVitalSign(
      this.patientService.patientIDsignal(),
      this.eocService.currentEocId(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.vitalSignDataSub.next(response.data);
        this.vitalSignLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant('Unable to retrieve vital signs')}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching vital signs:', err);
      },
    });
  }

  getVitalSign(
    patientId: string,
    episodeCareId: string,
    visitId: string
  ): Observable<IGenericServiceResponse<IVitalSignDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IVitalSignDetailed[]>>(
      `${environment.BACKEND_URL}VitalSign/${patientId}/${episodeCareId}/${visitId}`,
      {
        headers: headers,
      }
    );
  }

  addVitalSign(vitalSignObj: IAddVitalSign) {
    vitalSignObj.visitId = this.eocService.currentVisitId();
    vitalSignObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}VitalSign`,
      vitalSignObj,
      {
        headers: headers,
      }
    );
  }

  editVitalSign(vitalSignObj: IAddVitalSign) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}VitalSign/${vitalSignObj.id}`,
      vitalSignObj,
      {
        headers: headers,
      }
    );
  }

  deleteVitalSign(vitalSignId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}VitalSign/${vitalSignId}`,
      {
        headers: headers,
      }
    );
  }

  deleteVitalSignFromTable(vitalSignId: string) {
    // REMOVE THE Vaccination
    this.vitalSignDataSub.next(
      this.vitalSignDataSub.value.filter((v) => v.id?.toString() != vitalSignId)
    );
  }
}
