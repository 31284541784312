import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IAddComorbidity } from '../interfaces/Comorbidity/IAddComorbidity';
import { environment } from 'src/environments/environment';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import Swal from 'sweetalert2';
import { IComorbidityDetailed } from '../interfaces/Comorbidity/IComorbidityDetailed';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class ComorbidityService {
  public comorbidityModalSub = new BehaviorSubject<boolean>(false);
  public comorbidityEditSub = new BehaviorSubject<IAddComorbidity | undefined>(
    undefined
  );
  public comorbidityDataSub = new BehaviorSubject<IComorbidityDetailed[]>([]);
  public comorbidityLoadingSub = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}
  async getData() {
    this.comorbidityLoadingSub.next(true);
    this.getComorbidityData(
      +this.patientService.patientIDsignal(),
      +this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.comorbidityDataSub.next(response?.data);
        this.comorbidityLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Unable to retrieve comorbidity data'
          )}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching comorbidity data:', err);
      },
    });
  }
  getComorbidityData(
    patientId: number,
    visitId?: number
  ): Observable<IGenericServiceResponse<IComorbidityDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IComorbidityDetailed[]>>(
        `${environment.BACKEND_URL}Comorbidity/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IComorbidityDetailed[]>>(
        `${environment.BACKEND_URL}Comorbidity/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }
  addComorbidity(comorbidityObj: IAddComorbidity) {
    comorbidityObj.visitId = +this.eocService.currentVisitId();
    comorbidityObj.patientId = +this.patientService.patientIDsignal();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post(
      `${environment.BACKEND_URL}Comorbidity`,
      comorbidityObj,
      {
        headers: headers,
      }
    );
  }
  editComorbidity(comorbidityObj: IAddComorbidity) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.put(
      `${environment.BACKEND_URL}Comorbidity/${comorbidityObj.id}`,
      comorbidityObj,
      {
        headers: headers,
      }
    );
  }
  deleteComorbidity(comorbidityId: number): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Comorbidity/${comorbidityId}`,
      {
        headers: headers,
      }
    );
  }
  deleteComorbidityFromTable(comorbidityId: string) {
    // REMOVE THE comorbitity from the table
    this.comorbidityDataSub.next(
      this.comorbidityDataSub.value.filter(
        (ma) => ma.id?.toString() != comorbidityId
      )
    );
  }
}
