import { Component } from '@angular/core';
import { IAllergyDetailed } from 'src/app/interfaces/Detailed/IAllergyDetailed';
import { AllergyService } from 'src/app/services/allergy.service';
import { PatientService } from 'src/app/services/patient.service';
import { AllergiesAddEditComponent } from './allergies-add-edit/allergies-add-edit.component';
import Swal from 'sweetalert2';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { AllergiesExpandComponent } from './allergies-expand/allergies-expand.component';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.css'],
})
export class AllergiesComponent {
  public addComp = AllergiesAddEditComponent;
  public expandComp = AllergiesExpandComponent;

  private destroy$ = new Subject<void>();

  allergies?: IGetList<IAllergyDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(
    private readonly allergyService: AllergyService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.allergyService.allergyLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.allergyService.allergyNoKnownLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.allergyService.allergyDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IAllergyDetailed>) => {
        this.allergies = data;
      });

    this.allergyService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IAllergyDetailed) {
    this.allergyService.allergyModalSub.next(true);
    this.allergyService.allergyEditSub.next(item);
  }

  deleteFun(allergyId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Allergy')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.allergyService.deleteAllergy(allergyId.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Allergy deleted successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.allergyService.getData();
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant('Unable to delete Allergy')}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Allergy is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.allergyService.allergyNoKnownLoadingSub.next(true);
        this.allergyService.addNoKnownAllergy().subscribe({
          next: () => {
            Swal.fire({
              text: `${this.translate.instant(
                'No known Allergies set successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.allergyService.getData();
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Unable to set No Known Allergies'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error setting No Known Allergies:', err);
          },
        });
      } else {
        if (unknownid) {
          this.allergyService.allergyNoKnownLoadingSub.next(true);
          this.allergyService.deleteNoKnownAllergy(unknownid).subscribe({
            next: () => {
              Swal.fire({
                text: `${this.translate.instant(
                  'No known Allergies unset successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.allergyService.getData();
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to unset No Known Allergies'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.error('Error unsetting No Known Allergies:', err);
            },
          });
        }
      }
    }
  }
}
