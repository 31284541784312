<form
  [formGroup]="emergencyContactsForm"
  (keydown.enter)="enter($event)"
  [ngClass]="{ submitted: formSubmitted }"
>
  <div
    formArrayName="contacts"
    *ngFor="let contact of contacts.controls; let i = index"
  >
    <div [formGroupName]="i">
      <h3>
        {{ "Emergency Contact" | translate }} {{ i + 1 }}
        <span *ngIf="i == 0">- {{ "Admission Mediator" | translate }}</span>
      </h3>
      <div>
        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
          <div
            fxLayout="col"
            fxFlex="35"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "First Name" | translate }}
          </div>
          <div
            fxLayout="col"
            fxFlex="35"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Last Name" | translate }}
          </div>
          <div
            fxLayout="col"
            fxFlex="30"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Relationship with patient" | translate
            }}<span class="optional">{{ "(optional)" | translate }}</span>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="add-patient-row"
        >
          <div fxLayout="col" fxFlex="35" class="add-patient-col">
            <input
              class="add-patient-input"
              formControlName="firstName"
              [placeholder]="'First Name' | translate"
            />
          </div>
          <div fxLayout="col" fxFlex="35" class="add-patient-col">
            <input
              class="add-patient-input"
              formControlName="lastName"
              [placeholder]="'Last Name' | translate"
            />
          </div>
          <div fxLayout="col" fxFlex="30" class="add-patient-col">
            <nz-select
              (keydown.enter)="
                $event.stopPropagation(); $event.preventDefault()
              "
              class="add-patient-inputZorro"
              nzShowSearch
              nzAllowClear
              formControlName="closestRelativeId"
              [nzPlaceHolder]="'Relationship with patient' | translate"
            >
              <ng-container *ngFor="let relative of closestRelativesList">
                <nz-option
                  [nzLabel]="relative.name"
                  [nzValue]="relative.id"
                ></nz-option>
              </ng-container>
            </nz-select>
            <!-- 
            Conditionally show the free text input. 
            The number 7 is the id of option "Other" in closest relatives
            -->
            <input
              *ngIf="contact.get('closestRelativeId')?.value == 7"
              class="add-patient-input"
              formControlName="closestRelativeOther"
              placeholder="{{ 'Please specify' | translate }}"
            />
          </div>
        </div>
      </div>

      <div>
        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
          <div
            fxLayout="col"
            fxFlex="50"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Occupation" | translate
            }}<span class="optional">{{ "(optional)" | translate }}</span>
          </div>
          <div
            fxLayout="col"
            fxFlex="50"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Phone number" | translate }}
          </div>
          <div
            fxLayout="col"
            fxFlex="50"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Email" | translate
            }}<span class="optional">{{ "(optional)" | translate }}</span>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="add-patient-row"
        >
          <div fxLayout="col" fxFlex="50" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'Occupation' | translate"
              formControlName="occupation"
            />
          </div>
          <div fxLayout="col" fxFlex="50" class="add-patient-col">
            <ngx-intl-tel-input
              [cssClass]="'custom'"
              [preferredCountries]="[CountryISO.Cyprus, CountryISO.Greece]"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="false"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Cyprus"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="true"
              name="phone"
              formControlName="phoneNumber"
            ></ngx-intl-tel-input>
          </div>
          <div fxLayout="col" fxFlex="50" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'Email' | translate"
              formControlName="email"
            />
          </div>
        </div>
      </div>

      <div>
        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
          <div
            fxLayout="col"
            fxFlex="50"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Identifier Type" | translate }}
          </div>
          <div
            fxLayout="col"
            fxFlex="50"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Identifier" | translate }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="add-patient-row"
        >
          <div fxLayout="col" fxFlex="50" class="add-patient-col">
            <nz-select
              (keydown.enter)="
                $event.stopPropagation(); $event.preventDefault()
              "
              class="add-patient-inputZorro"
              nzShowSearch
              nzAllowClear
              [nzPlaceHolder]="'Identifier Type' | translate"
              formControlName="identifierType"
              (ngModelChange)="onIdentifierTypeChange($event, i)"
            >
              <nz-option
                [nzLabel]="'National ID' | translate"
                nzValue="nationalId"
              ></nz-option>
              <nz-option
                [nzLabel]="'Passport' | translate"
                nzValue="passport"
              ></nz-option>
              <nz-option
                [nzLabel]="'Alien Resident Card' | translate"
                nzValue="arc"
              ></nz-option>
            </nz-select>
          </div>
          <div fxLayout="col" fxFlex="50" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'Identifier' | translate"
              formControlName="identifier"
            />
            <nz-select
              (keydown.enter)="
                $event.stopPropagation(); $event.preventDefault()
              "
              *ngIf="contact.get('identifierType')?.value !== 'arc'"
              class="add-patient-inputZorro"
              nzShowSearch
              nzAllowClear
              [nzPlaceHolder]="'Issuing Country' | translate"
              formControlName="identifierCountry"
            >
              <ng-container *ngFor="let country of countriesList">
                <nz-option
                  nzLabel="{{ country.name }}"
                  nzValue="{{ country.id }}"
                ></nz-option>
              </ng-container>
            </nz-select>
          </div>
        </div>
      </div>

      <!-- Emergency Contact's Address data  -->
      <div>
        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
          <div
            fxLayout="col"
            fxFlex="100"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Street" | translate }}
            <span class="optional">{{ "(optional)" | translate }}</span>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="add-patient-row"
        >
          <div fxLayout="col" fxFlex="100" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'Street' | translate"
              formControlName="street"
            />
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
          <div
            fxLayout="col"
            fxFlex="33"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Street Number" | translate }}
            <span class="optional">{{ "(optional)" | translate }}</span>
          </div>
          <div
            fxLayout="col"
            fxFlex="33"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Apartment Number" | translate
            }}<span class="optional">{{ "(optional)" | translate }}</span>
          </div>
          <div
            fxLayout="col"
            fxFlex="33"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Postcode" | translate }}
            <span class="optional">{{ "(optional)" | translate }}</span>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="add-patient-row"
        >
          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'Street Number' | translate"
              formControlName="streetNumber"
            />
          </div>
          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'Apartment Number' | translate"
              formControlName="apartmentNumber"
            />
          </div>
          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'Postcode' | translate"
              formControlName="postcode"
            />
          </div>
        </div>
      </div>

      <div>
        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
          <div
            fxLayout="col"
            fxFlex="33"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Country" | translate }}
            <span class="optional">{{ "(optional)" | translate }}</span>
          </div>
          <div
            fxLayout="col"
            fxFlex="33"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "Town" | translate }}
            <span class="optional">{{ "(optional)" | translate }}</span>
          </div>
          <div
            fxLayout="col"
            fxFlex="33"
            class="add-patient-col"
            fxLayoutAlign="start center"
          >
            {{ "District" | translate }}
            <span class="optional">{{ "(optional)" | translate }}</span>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="add-patient-row"
        >
          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <nz-select
              (keydown.enter)="
                $event.stopPropagation(); $event.preventDefault()
              "
              class="add-patient-inputZorro"
              nzShowSearch
              nzAllowClear
              [nzPlaceHolder]="'Country' | translate"
              formControlName="country"
              (ngModelChange)="onCountryChange($event)"
            >
              <ng-container *ngFor="let place of countriesList">
                <nz-option
                  [nzLabel]="place.name"
                  [nzValue]="place.id"
                ></nz-option>
              </ng-container>
            </nz-select>
          </div>

          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <ng-container *ngIf="!isCountryCyprus; else cyprusDropdown">
              <input
                class="add-patient-input"
                [placeholder]="'Town' | translate"
                formControlName="town"
              />
            </ng-container>
            <!-- Dropdown if Cyprus -->
            <ng-template #cyprusDropdown>
              <nz-select
                (keydown.enter)="
                  $event.stopPropagation(); $event.preventDefault()
                "
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="'Select a Town' | translate"
                formControlName="town"
              >
                <ng-container *ngFor="let city of cyprusCities">
                  <nz-option
                    [nzLabel]="city | translate"
                    [nzValue]="city"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </ng-template>
          </div>

          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <input
              class="add-patient-input"
              [placeholder]="'District' | translate"
              formControlName="district"
            />
          </div>
        </div>
      </div>

      <button
        *ngIf="contacts.length > 1"
        class="back-button"
        (click)="removeEmergencyContact(i)"
        type="button"
      >
        {{ "Remove" | translate }}
      </button>
    </div>
  </div>
  <button
    class="add-patient-button"
    (click)="addEmergencyContact()"
    type="button"
  >
    {{ "Add another emergency contact" | translate }}
  </button>
</form>
