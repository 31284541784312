import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { MatIconModule } from '@angular/material/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IImagingDetailed } from 'src/app/interfaces/Imaging/IImagingDetailed';
import { ImagingService } from 'src/app/services/imaging.service';
import { Subject, takeUntil } from 'rxjs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import Swal from 'sweetalert2';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ImagingFilesAddComponent } from './imaging-files-add/imaging-files-add.component';
import moment from 'moment';
import { NzImageModule } from 'ng-zorro-antd/image';
import { PatientService } from 'src/app/services/patient.service';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'app-imaging-files',
  templateUrl: './imaging-files.component.html',
  styleUrls: ['./imaging-files.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    TooltipComponent,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NzPaginationModule,
    NgTemplateOutlet,
    BrowserModule,
    PdfViewerModule,
    NzSpinModule,
    NzImageModule,
  ],
})
export class ImagingFilesComponent {
  @Input() isPreview: boolean = false;
  @Input() isLoading?: boolean = false;
  isPreviewLoading?: boolean = false;

  @Input() pagination?: boolean = true;

  public addComp = ImagingFilesAddComponent;

  private destroy$ = new Subject<void>();

  fileObj?: string;
  fileItem?: IImagingDetailed;
  fileType?: string;
  fileName?: string;

  paginatorSize: number = 0;

  @Input() data?: IImagingDetailed[];

  currentPageIndex: number = 1;
  pageSize: number = 5;

  reportFiles = true;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal
  isVisible = false;

  constructor(
    private readonly imagingService: ImagingService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly patientSevice: PatientService
  ) {}

  ngOnInit() {
    this.imagingService.imagingLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    if (!this.data) {
      this.imagingService.imagingDataSub
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: IImagingDetailed[]) => {
          this.data = data;

          if (data.at(0)) {
            this.reportFiles = true;
            this.preview(data.at(0));
          }
        });

      this.imagingService.getData();
    }
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  hasUnsubmitted() {
    return this.getData()?.find(
      (d) => d?.['isSubmitted'] !== undefined && !d?.['isSubmitted']
    );
  }

  getData() {
    if (this.pagination) {
      const startIndex = (this.currentPageIndex - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this?.data?.slice(startIndex, endIndex) || [];
    }
    return this?.data;
  }

  handlePageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPageIndex = 1; // Reset to the first page when changing page size
  }

  openModal(item?: IImagingDetailed) {
    this.imagingService.imagingModalSub.next(true);
    this.imagingService.imagingEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Imaging Record')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.imagingService.deleteImaging(id.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Imaging deleted successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            if (id == this?.fileItem?.id) {
              this.fileObj = undefined;
              this.fileItem = undefined;
            }

            this.imagingService.deleteImagingFromTable(id.toString());
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant('Unable to delete Imaging')}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Imaging is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  preview(item?: IImagingDetailed, index?: number) {
    if (item) {
      this.fileItem = item;
      this.paginatorSize = item?.paths?.length || 0;
    }

    this.isPreviewLoading = true;

    this.reportFiles
      ? this.imagingService
          .downloadReport(this.fileItem?.id?.toString()!)
          .subscribe({
            next: (res?: Blob) => {
              if (res) {
                const reader = new FileReader();

                this.fileType = res.type;
                this.isPreviewLoading = false;

                reader.onload = () => {
                  this.fileObj = reader.result?.toString();
                };

                reader.readAsDataURL(res);

                this.getFileName(index);
              }
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to retrieve the document'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              this.fileObj = undefined;
              this.fileItem = undefined;

              this.isPreviewLoading = false;
            },
          })
      : this.imagingService
          .preview(
            index
              ? this.fileItem?.paths?.at(index)?.id?.toString()!
              : this.fileItem?.paths?.at(0)?.id?.toString()!
          )
          .subscribe({
            next: (res?: Blob) => {
              if (res) {
                const reader = new FileReader();

                this.fileType = res.type;
                this.isPreviewLoading = false;

                reader.onload = () => {
                  this.fileObj = reader.result?.toString();
                };

                reader.readAsDataURL(res);

                this.getFileName(index);
              }
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to retrieve the document'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              this.fileObj = undefined;
              this.fileItem = undefined;

              this.isPreviewLoading = false;
            },
          });
  }

  pageChange(event: number) {
    this.preview(this.fileItem, event - 1);
  }

  downloadPDF() {
    if (this.fileItem && this.fileType) {
      this.patientSevice.patientInfoSub
        .pipe(takeUntil(this.destroy$))
        .subscribe((d) => {
          const firstName = d.firstName || '';
          const lastName = d.lastName || '';

          if (!this.reportFiles) {
            this.imagingService.download(
              this.fileItem!,
              this.fileType!,
              firstName,
              lastName
            );
          } else {
            this.imagingService.downloadPdf(
              this.fileItem!,
              this.fileType!,
              firstName,
              lastName
            );
          }
        });
    }
  }

  getValue(field: string, value?: string) {
    if (!value) {
      return '-';
    }

    if (field?.toLowerCase()?.includes('date time')) {
      return moment(value).format('YYYY-MM-DD h:mm:ss a');
    } else if (field?.toLowerCase()?.includes('date')) {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return value;
    }
  }

  ngOnDestroy() {
    if (this.fileObj) {
      URL.revokeObjectURL(this.fileObj as string);
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  handleCancel() {
    this.isVisible = false;

    this.modalRef.destroy();
  }

  getFileName(index?: number) {
    const path = this.reportFiles
      ? this.fileItem?.reportPath
      : this.fileItem?.paths?.at(index || 0)?.path;
    const filename = path?.split(/[/\\]/).pop();
    this.fileName = filename || '';
  }
}
