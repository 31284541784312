/* src/app/components/ehr/user-management/user-management-edit/user-management-edit.component.css */
.required-icon {
  color: red;
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.titleBar {
  display: flex;
  align-items: center;
}
.required-asterisk {
  color: red;
  margin-left: 0.25rem;
}
.titleBar::before {
  content: "";
  width: 5px;
  height: 30px;
  background-color: #0d9488;
  margin-right: 10px;
}
.edit-user-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-right: 100px;
}
::ng-deep .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #0d9488 !important;
}
.loading-text {
  margin-top: 10px;
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Inter", sans-serif !important;
}
.edit-user-form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  outline: 5px #317d88;
  width: 600px;
}
.buttons {
  margin-top: 30px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.save-button {
  background-color: #317d88 !important;
  border: 1px solid #317d88 !important;
  color: white !important;
  margin-right: 20px;
}
.save-button:disabled {
  cursor: not-allowed;
  background-color: #b5b5b5 !important;
  border: 1px solid #b5b5b5 !important;
  color: white !important;
}
.cancel-button {
  background-color: rgb(202, 79, 79) !important;
  border: 1px solid rgb(202, 79, 79) !important;
  color: white !important;
}
.email-button {
  background-color: #ffd470 !important;
  color: white !important;
}
.mail-section {
  margin-top: 10px;
  padding-right: 20px;
}
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
.add-patient-row {
  margin-bottom: 1.5em;
}
.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
  height: 35px !important;
}
.add-patient-input {
  width: 100%;
  color: black;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
select.ng-valid.ng-touched {
  color: black;
}
input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: #c2c2c2;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
select.ng-invalid.ng-touched,
.nz-select.ng-invalid.ng-touched,
.ngx-intl-tel-input.ng-invalid.ng-touched {
  border: 1px solid #c0392b !important;
  border-radius: 2px !important;
}
.submitted .ng-invalid {
  border: red 1px solid;
  border-radius: 2px;
}
::ng-deep .ant-select-selector {
  width: 100% !important;
  color: black !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  min-height: 35px !important;
}
.multiselector {
  margin-left: 1em !important;
  margin-right: 1em !important;
}
::ng-deep section ::ng-deep .tel-form {
  height: 35px !important;
}
::ng-deep section ::ng-deep .tel-form ::ng-deep mat-form-field.prefix-form-field {
  width: 210px !important;
  padding-left: 0px !important;
}
::ng-deep section ::ng-deep .tel-form ::ng-deep mat-form-field ::ng-deep .mdc-text-field {
  height: 35px !important;
  align-items: center !important;
}
::ng-deep .mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mdc-circular-progress__determinate-circle {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep ngx-intl-tel-input input {
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::ng-deep ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}
::ng-deep ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}
::ng-deep ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}
::ng-deep ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
.error-message {
  display: block;
  color: red;
  font-size: 12px;
  margin-top: 2px;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}
/*# sourceMappingURL=user-management-edit.component-LGDM6K2P.css.map */
