import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IGlasgowDetailed } from '../interfaces/Glasgow/IGlasgowDetailed';
import { IAddGlasgow } from '../interfaces/Glasgow/IAddGlasgow';
import { IGlasgowDropdown } from '../interfaces/Glasgow/IGlasgowDropdown';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlasgowService {
  public glasgowModalSub = new BehaviorSubject<boolean>(false);

  public glasgowEditSub = new BehaviorSubject<IGlasgowDetailed | undefined>(
    undefined
  );

  public glasgowDataSub = new BehaviorSubject<IGlasgowDetailed[]>([]);

  public glasgowLoadingSub = new BehaviorSubject<boolean>(false);
  public glasgowLoadingSub$ = this.glasgowLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.glasgowLoadingSub.next(true);
    this.getGlasgow(
      this.patientService.patientIDsignal(),
      this.eocService.currentEocId(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.glasgowDataSub.next(response?.data!);
        this.glasgowLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant('Unable to retrieve Glasgow data')}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching Glasgow data:', err);
      },
    });
  }

  getGlasgow(
    patientId: string,
    episodeCareId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IGlasgowDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IGlasgowDetailed[]>>(
      `${environment.BACKEND_URL}Glasgow/${patientId}/${episodeCareId}/${visitId}`,
      {
        headers: headers,
      }
    );
  }

  getGlasgowDropdown(): Observable<IGenericServiceResponse<IGlasgowDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IGlasgowDropdown>>(
      `${environment.BACKEND_URL}Glasgow/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addGlasgow(abgObj: IAddGlasgow) {
    abgObj.visitId = this.eocService.currentVisitId();
    abgObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}Glasgow`, abgObj, {
      headers: headers,
    });
  }

  editGlasgow(abgObj: IAddGlasgow) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Glasgow/${abgObj.id}`,
      abgObj,
      {
        headers: headers,
      }
    );
  }

  deleteGlasgow(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Glasgow/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deleteGlasgowFromTable(glasgowId: string) {
    // REMOVE THE Glasgow record
    this.glasgowDataSub.next(
      this.glasgowDataSub.value?.filter(
        (abg) => abg.id?.toString() != glasgowId
      )
    );
  }
}
