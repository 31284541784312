import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { IPregnancyHistoryDetailed } from '../../../../../../interfaces/PregnancyHistory/IPregnancyHistoryDetailed';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { PregnancyHistoryAddEditComponent } from './pregnancy-history-add-edit/pregnancy-history-add-edit.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pregnancy-history',
  templateUrl: './pregnancy-history.component.html',
  styleUrls: ['./pregnancy-history.component.css'],
})
export class PregnancyHistoryComponent {
  public addComp = PregnancyHistoryAddEditComponent;
  // public expandComp = PregnancyHistoryExpandComponent;

  private destroy$ = new Subject<void>();

  pregnancyHistory?: IPregnancyHistoryDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.pregnancyHistoryService.pregnancyHistoryLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.pregnancyHistoryService.pregnancyHistoryDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IPregnancyHistoryDetailed[]) => {
        this.pregnancyHistory = data;
      });

    this.pregnancyHistoryService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IPregnancyHistoryDetailed) {
    this.pregnancyHistoryService.pregnancyHistoryModalSub.next(true);
    this.pregnancyHistoryService.pregnancyHistoryEditSub.next(item);
  }

  deleteFun(pregnancyHistoryId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Pregnancy History')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.pregnancyHistoryService
          .deletePregnancyHistory(pregnancyHistoryId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Pregnancy History deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pregnancyHistoryService.deletePregnancyHistoryFromTable(
                pregnancyHistoryId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to delete Pregnancy History'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Pregnancy History is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  // expandSet = new Set<number>();
  // onExpandChange(id: number, checked: boolean): void {
  //   if (checked) {
  //     this.expandSet.add(id);
  //   } else {
  //     this.expandSet.delete(id);
  //   }
  // }
}
