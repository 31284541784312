import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { Subject, takeUntil } from 'rxjs';
import { CurrentProblemsAddEditComponent } from './current-problems-add-edit/current-problems-add-edit.component';
import { CurrentProblemsExpandComponent } from './current-problems-expand/current-problems-expand.component';
import { IProblemsDetailed } from '../../../../../../interfaces/Problems/IProblemsDetailed';
import { CurrentProblemsService } from '../../../../../../services/currentProblems.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-current-problems',
  templateUrl: './current-problems.component.html',
  styleUrls: ['./current-problems.component.css'],
})
export class CurrentProblemsComponent {
  public addComp = CurrentProblemsAddEditComponent;
  public expandComp = CurrentProblemsExpandComponent;

  private destroy$ = new Subject<void>();

  currentProblems?: IGetList<IProblemsDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentProblemsService.currentProblemLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.currentProblemsService.currentProblemNoKnownLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.currentProblemsService.currentProblemDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IProblemsDetailed>) => {
        this.currentProblems = data;
      });

    this.currentProblemsService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IProblemsDetailed) {
    this.currentProblemsService.currentProblemModalSub.next(true);
    this.currentProblemsService.currentProblemEditSub.next(item);
  }

  deleteFun(problemId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Current Problem')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentProblemsService
          .deleteProblems(problemId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Problem deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.currentProblemsService.getData();
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant('Unable to delete problem')}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Problem is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.currentProblemsService.currentProblemNoKnownLoadingSub.next(true);
        this.currentProblemsService.addNoKnownProblem().subscribe({
          next: () => {
            Swal.fire({
              text: `${this.translate.instant(
                'No known Current Problems set successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.currentProblemsService.getData();
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Unable to set No Known Problems'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error setting No Known Problems:', err);
          },
        });
      } else {
        if (unknownid) {
          this.currentProblemsService.currentProblemNoKnownLoadingSub.next(
            true
          );
          this.currentProblemsService
            .deleteNoKnownProblems(unknownid)
            .subscribe({
              next: () => {
                Swal.fire({
                  text: `${this.translate.instant(
                    'No known Problems unset successfully'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });

                this.currentProblemsService.getData();
              },
              error: (err) => {
                Swal.fire({
                  text: `${this.translate.instant(
                    'Unable to unset No Known Problems'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });
                console.error('Error unsetting No Known Problems:', err);
              },
            });
        }
      }
    }
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
}
