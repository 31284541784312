import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard {
  constructor(private readonly translate: TranslateService) {}

  canDeactivate(): Observable<boolean> | boolean {
    return this.showDiscardChangesDialog();
  }

  showDiscardChangesDialog(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      Swal.fire({
        text: `${this.translate.instant(
          'All unsaved data will be lost. Do you want to continue?'
        )}`,
        showDenyButton: false,
        showCancelButton: true,
        cancelButtonText: `${this.translate.instant('No')}`,
        confirmButtonText: `${this.translate.instant('Yes')}`,
      }).then((result) => {
        if (result.isConfirmed) {
          observer.next(true);
        } else if (result.isDenied) {
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }
}
