/* src/app/components/ehr/patients/patients.component.css */
.patients-search {
  margin-top: 2em;
  margin-bottom: 2em;
  height: 10%;
}
.patients-table {
  height: 90%;
  min-width: 100%;
}
.mat-mdc-form-field-focus-overlay {
  background-color: red !important;
}
.patients-search-input {
  height: 35px !important;
  margin-right: 3em;
}
@media (max-width: 1140px) {
  .patients-search-input {
    margin-right: 1em !important;
  }
}
@media only screen and (max-width: 1279.99px) {
  .btn-text {
    display: none !important;
  }
}
.patients-search-buttons {
  height: 35px !important;
  margin-right: 1em;
}
.mat-mdc-input-element {
  height: 35px !important;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
::placeholder {
  color: #c2c2c2;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
}
.search-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-button:hover {
  cursor: pointer;
}
.clear-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: white;
  color: #317d88;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-button {
  height: 100%;
  border: 1px solid #0d9488;
  border-radius: 4px;
  background-color: #0d9488;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear-button:hover {
  cursor: pointer;
}
.register-button:hover {
  cursor: pointer;
}
.action-button {
  color: #317d88;
  border: 1px solid #317d88;
  border-radius: 15px;
  background-color: transparent;
  padding: 5px;
  min-width: 75%;
  font-size: 12px;
}
.action-button:hover {
  background-color: #317d88;
  color: white;
  cursor: pointer;
}
::ng-deep .mat-column-actions {
  max-width: 100px !important;
  width: 100px !important;
  min-width: 100px !important;
  padding-right: 0px !important;
}
.patients-content-paginator-hide {
  display: none !important;
}
.parent-body {
  height: 100%;
}
mat-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.patients-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
}
::ng-deep .mat-mdc-paginator-outer-container {
  margin-top: 0em;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
input {
  color: black !important;
}
.h-100 {
  height: 100%;
}
.allpatients-spinner {
  margin-top: 3em;
}
.no-patients {
  margin-top: 15em;
  font-size: large;
}
.sort-btn {
  margin-left: 5px;
  height: 16px;
  width: 16px;
  font-size: 16px !important;
}
/*# sourceMappingURL=patients.component-3D3WNXWZ.css.map */
