<div [class]="
    isInpatientView() ? 'inpatients-header-body' : 'patients-header-body'
  ">
  <div fxLayout="row" class="w-100" *ngIf="!patientLoading">
    <div fxLayout="col" fxLayoutAlign="flex-start center">
      <div class="split-lines pr-pp">
        <nz-avatar class="profilePicture" [nzShape]="'circle'" [nzSize]="80" nzIcon="user"
          [nzSrc]="avatarImageUrl"></nz-avatar>
      </div>
    </div>

    <div fxLayout="col" class="split-lines pr-50" fxLayoutAlign="flex-start center">
      <div fxLayout="row">
        <div class="header-details">
          <div *ngIf="!episodeOfCare" (click)="goToPatients()" class="header-patients"
            fxLayoutAlign="flex-start center">
            {{ "Patient" | translate }} >
          </div>
          <div *ngIf="episodeOfCare && !isPilotStudy && !isInpatientView()" (click)="goToProfile()"
            class="header-patients" fxLayoutAlign="flex-start center">
            {{ "Profile" | translate }} >
          </div>
          <div class="fullname">
            {{ patientInfo?.firstName }} {{ patientInfo?.lastName }}
          </div>

          <span class="custom-button" (click)="getPatientDetails()">
            <mat-icon *ngIf="!showPatientInformation" class="information-icon"
              nzTooltipTitle="{{ 'View patient information' | translate }}" nzTooltipPlacement="right" nz-tooltip>
              remove_red_eye
            </mat-icon>
            <mat-icon *ngIf="showPatientInformation" class="information-icon"
              nzTooltipTitle="{{ 'Hide patient information' | translate }}" nzTooltipPlacement="right" nz-tooltip>
              visibility_off
            </mat-icon>
          </span>
        </div>
      </div>

      <div fxLayout="row" *ngIf="!patientLoading">
        <div class="header-details-2">
          <div *ngIf="patientInfo?.dateOfBirth">
            <span fxHide.gt-md nz-tooltip nzTooltipTitle="{{ 'y/o' | translate }}">
              {{ calculateAge(patientInfo?.dateOfBirth!) }}
            </span>
            <span fxHide.lt-lg>
              {{ calculateAge(patientInfo?.dateOfBirth!) }}
              {{ "y/o" | translate }}</span>
          </div>

          <div *ngIf="patientInfo?.gender">
            <span class="divider">|</span>
            {{ patientInfo?.gender! | translate }}
          </div>

          <div *ngIf="patientInfo?.height">
            <span class="divider">|</span>
            <span fxHide.gt-md nz-tooltip nzTooltipTitle="{{ 'cm' | translate }}">
              {{ patientInfo?.height }}
            </span>
            <span fxHide.lt-lg>
              {{ patientInfo?.height }} {{ "(cm)" | translate }}</span>
          </div>

          <div *ngIf="patientInfo?.weight">
            <span class="divider">|</span>
            <span fxHide.gt-md nz-tooltip nzTooltipTitle="{{ 'kg' | translate }}">
              {{ patientInfo?.weight }}
            </span>
            <span fxHide.lt-lg>
              {{ patientInfo?.weight }} {{ "(kg)" | translate }}</span>
          </div>

          <div *ngIf="patientInfo?.weight && patientInfo?.height">
            <span class="divider">|</span>
            <span fxHide.gt-md nz-tooltip nzTooltipTitle="{{ 'BMI' | translate }}">
              {{
              calculatePatientBMI(patientInfo?.weight, patientInfo?.height)
              }}
            </span>
            <span fxHide.lt-lg>
              {{ "BMI: " | translate }}
              {{
              calculatePatientBMI(patientInfo?.weight, patientInfo?.height)
              }}</span>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="col" class="split-lines" fxLayoutAlign="flex-start center">
      <div fxLayout="row" *ngIf="!patientLoading">
        <div class="header-details">
          <div class="header-details-2">
            <div *ngIf="patientInfo?.bloodType">
              <i nz-icon nzType="icons:blood" nzTheme="outline" class="header-icon pr-5"></i>
              {{ patientInfo?.bloodType }}
            </div>

            <span *ngIf="patientInfo?.bloodType && patientInfo?.assignedDoctors" class="divider">|</span>

            <div fxLayout="row" *ngIf="
                patientInfo?.assignedDoctors &&
                getPrimaryDoctor()?.doctorFullName
              ">
              <i nz-icon nzType="icons:doctor" nzTheme="outline" class="header-icon pl-5 pr-5"></i>
              {{ getPrimaryDoctor()?.doctorFullName }} (Primary)
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" *ngIf="!patientLoading">
        <div class="header-details-2 pl-5">
          <span *ngIf="
              patientInfo?.inPatientDto?.buildingName ||
              patientInfo?.inPatientDto?.floorName ||
              patientInfo?.inPatientDto?.wardName ||
              patientInfo?.inPatientDto?.bedNumber
            ">
            <i nz-icon nzType="icons:bed" nzTheme="outline" class="pr-10"></i>
          </span>

          <span *ngIf="patientInfo?.inPatientDto?.buildingName">{{ patientInfo?.inPatientDto?.buildingName }}
          </span>

          <span *ngIf="patientInfo?.inPatientDto?.floorName">
            <span *ngIf="patientInfo?.inPatientDto?.floorName" class="divider">|</span>
            {{ patientInfo?.inPatientDto?.floorName }}
          </span>

          <span *ngIf="patientInfo?.inPatientDto?.wardName">
            <span *ngIf="patientInfo?.inPatientDto?.wardName" class="divider">|</span>
            {{ patientInfo?.inPatientDto?.wardName }}
          </span>

          <span *ngIf="patientInfo?.inPatientDto?.bedNumber">
            <span *ngIf="patientInfo?.inPatientDto?.bedNumber" class="divider">|</span>
            {{ patientInfo?.inPatientDto?.bedNumber }}
          </span>
          &nbsp;
        </div>
      </div>
    </div>

    <div fxLayout="col" fxFlex fxLayoutAlign="flex-end center">
      <button nz-button class="add-episode" (click)="addEpisodeOfCare()" *ngIf="!episodeOfCare && !isReadOnly"
        [nzLoading]="isEOCloading">
        <mat-icon>{{ "add" | translate }}</mat-icon>
        <span class="btn-text">{{ "Add Episode of Care" | translate }}</span>
      </button>

      <button nz-button *ngIf="episodeOfCare && (isEOC() || isInpatientView()) && !isPreview" class="discard-button"
        (click)="discardVisit()">
        <i nz-icon nzType="icons:delete" nzTheme="outline"></i><span class="btn-text">{{ "Discard" | translate }}</span>
      </button>

      <button nz-button *ngIf="episodeOfCare && (isEOC() || isInpatientView()) && !isPreview" class="preview-button"
        (click)="loadPreview()">
        <i nz-icon nzType="icons:eye" nzTheme="outline"></i>
        <span class="btn-text">{{ "Preview & Save" | translate }}</span>
      </button>

      <button nz-button *ngIf="episodeOfCare && (isEOC() || isInpatientView()) && isPreview" class="back-button"
        (click)="unloadPreview()">
        <i nz-icon nzType="icons:rollback" nzTheme="outline"></i>
        <span class="btn-text">{{ "Back" | translate }}</span>
      </button>

      <button nz-button *ngIf="
          episodeOfCare &&
          (isEOC() || isInpatientView()) &&
          isPreview &&
          previewValid
        " class="save-button" [ngClass]="{ 'save-button-hover': potentialDiagnosis }" (click)="saveVisit()"
        [disabled]="!potentialDiagnosis">
        <i nz-icon nzType="icons:save" nzTheme="outline"></i>
        <span class="btn-text">{{ "Save Visit" | translate }}</span>
      </button>
    </div>
  </div>
</div>