import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAddAccommodation } from 'src/app/interfaces/Accommodation/IAddAccommodation';
import { IAddDischarge } from 'src/app/interfaces/Discharge/IAddDischarge';
import { IDischargeDropdown } from 'src/app/interfaces/Discharge/IDischargeDropdown';
import {
  IAccommodationDropdown,
  IBuilding,
  IFloor,
  IRoom,
  IWard,
} from 'src/app/interfaces/Dropdown/IAccommodationDropdown';
import { ICoding } from 'src/app/interfaces/ICoding';
import { IInpatient } from 'src/app/interfaces/IInpatient';
import {
  ardsRV,
  glasgowScoreRV,
  mewsRV,
} from 'src/app/interfaces/IReferenceValue';
import { ISearchInpatient } from 'src/app/interfaces/ISearchInpatient';
import { AccommodationService } from 'src/app/services/accomodation.service';
import { AdmissionService } from 'src/app/services/admission.service';
import { AllergyService } from 'src/app/services/allergy.service';
import { CarePlanService } from 'src/app/services/carePlan.service';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { CurrentProblemsService } from 'src/app/services/currentProblems.service';
import { DischargeService } from 'src/app/services/discharge.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { ImagingService } from 'src/app/services/imaging.service';
import { InitialAssessmentService } from 'src/app/services/initialAssessment.service';
import { InpatientService } from 'src/app/services/inpatient.service';
import { InPatientExternalRefresh } from 'src/app/services/inPatientExternalRefresh.service';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { MedicalAlertService } from 'src/app/services/medicalAlert.service';
import { MedicalDeviceService } from 'src/app/services/medicalDevice.service';
import { MedicalHistoryService } from 'src/app/services/medicalHistory.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import { PatientService } from 'src/app/services/patient.service';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ResolvedProblemsService } from 'src/app/services/resolvedProblems.service';
import { SocialHistoryService } from 'src/app/services/socialHistory.service';
import { SupportingDocumentsService } from 'src/app/services/supportingDocuments.service';
import { TokenService } from 'src/app/services/token.service';
import { TravelHistoryService } from 'src/app/services/travelHistory.service';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-in-patients',
  templateUrl: './in-patients.component.html',
  styleUrls: ['./in-patients.component.css'],
  providers: [DatePipe],
})
export class InPatientsComponent {
  @ViewChild('inpaginator', { static: true }) paginator!: MatPaginator;

  @ViewChild('bedModalRef', { static: true }) bedModalRef!: NzModalRef;
  @ViewChild('dischargeModalRef', { static: true })
  dischargeModalRef!: NzModalRef;

  icu: boolean = false;

  bedForm: FormGroup = new FormGroup({});
  dischargeForm: FormGroup = new FormGroup({});

  bedSubmitted = false;
  dischargeSubmitted = false;

  situationDisabled = false;

  isLoading = false;
  isNewVisitLoading = false;

  isAssignLoading = false;
  isDischargeLoading = false;

  isMoveVisible = false;
  isAssignVisible = false;
  isDischargeVisible = false;

  isPreRegistration: boolean = false;

  public availablePatients: NzSelectOptionInterface[] = [];
  public reasons: ICoding[] = [];

  public data: MatTableDataSource<IInpatient> =
    new MatTableDataSource<IInpatient>([]);
  private tableData: any[] = [];

  public totalRecords: number = 0;

  public metricsData: any[] = [];

  private userRoles: string[] = [];
  public dischargeVisible?: boolean;

  dischargeDropdown?: IDischargeDropdown;

  finalDiagnosis: NzSelectOptionInterface[] = [];

  dropdownData: IAccommodationDropdown = {
    buildings: [],
  };

  ddBuildings: IBuilding[] = [];
  ddWards: IWard[] = [];
  ddFloors: IFloor[] = [];
  ddRooms: IRoom[] = [];

  selectedBuilding: IBuilding | null = null;
  selectedFloor: IFloor | null = null;
  selectedWard: IWard | null = null;
  selectedRoom: IRoom | null = null;
  selectedPatientFullName: string = '';
  selectedPatientID?: number;

  public displayedColumns: string[] = [];

  public ardsRV = ardsRV;
  public mewsRV = mewsRV;
  public glasgowScoreRV = glasgowScoreRV;

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }

  constructor(
    private readonly inpatientService: InpatientService,
    private readonly keycloakService: KeycloakService,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly allergyService: AllergyService,
    private readonly vaccinationService: VaccinationService,
    private readonly medicalAlertService: MedicalAlertService,
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly procedureService: ProcedureService,
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly carePlanService: CarePlanService,
    private readonly socialHistoryService: SocialHistoryService,
    private readonly initialAssessmentService: InitialAssessmentService,
    private readonly travelHistoryService: TravelHistoryService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pastMedicationService: PastMedicationService,
    private readonly imagingService: ImagingService,
    private readonly labService: LaboratoryFileService,
    private readonly supportingDocumentsService: SupportingDocumentsService,
    private readonly hysteroscopyFileService: HysteroscopyFileService,
    private readonly vitalSignService: VitalSignService,
    private readonly dischargeService: DischargeService,
    private readonly translate: TranslateService,
    private readonly tokenService: TokenService,
    private refreshService: InPatientExternalRefresh,
    private route: ActivatedRoute
  ) {
    this.bedForm = this.fb.group({
      patientValue: ['', Validators.required],
      bedValue: ['', Validators.required],
      methodValue: ['', Validators.required],
      noteValue: [''],
    });

    this.dischargeForm = this.fb.group({
      diagnosisValue: ['', Validators.required],
      dischargeTypeValue: ['', Validators.required],
      dischargeSituationValue: ['', Validators.required],
      dischargeTypeNotesValue: [''],
      dischargeSituationNotesValue: [''],
      eocValue: ['', Validators.required],
    });
  }

  getRow(element: any) {
    return (
      this.metricsData?.find((d: any) => d?.id === element?.id) || undefined
    );
  }

  onBuildingChange(building: IBuilding) {
    this.selectedBuilding = building;

    this.selectedFloor = null;
    this.selectedWard = null;
    this.selectedRoom = null;

    this.ddFloors = building.floors;
    this.ddWards = building.floors.flatMap((floor) => floor.wards);
    this.ddRooms = this.ddWards.flatMap((ward) => ward.rooms);

    this.refreshTable();
  }

  onFloorChange(floor: IFloor) {
    this.selectedFloor = floor;

    this.selectedWard = null;
    this.selectedRoom = null;

    if (floor) {
      this.ddWards = floor.wards;
      this.ddRooms = floor.wards.flatMap((ward) => ward.rooms);
    } else {
      this.ddFloors =
        this.ddBuildings.find((b) => b.id == this.selectedBuilding?.id)
          ?.floors || this.ddBuildings.flatMap((b) => b.floors);
      this.ddWards =
        this.ddFloors.find((f) => f.id == this.selectedFloor?.id)?.wards ||
        this.ddFloors.flatMap((f) => f.wards);
      this.ddRooms =
        this.ddWards.find((w) => w.id == this.selectedWard?.id)?.rooms ||
        this.ddWards.flatMap((w) => w.rooms);
    }

    this.refreshTable();
  }

  onWardChange(ward: IWard) {
    this.selectedWard = ward;

    this.selectedRoom = null;

    if (ward) {
      this.ddRooms = ward.rooms;
    } else {
      this.ddWards =
        this.ddFloors.find((f) => f.id == this.selectedFloor?.id)?.wards ||
        this.ddFloors.flatMap((f) => f.wards);
      this.ddRooms =
        this.ddWards.find((w) => w.id == this.selectedWard?.id)?.rooms ||
        this.ddWards.flatMap((w) => w.rooms);
    }

    this.refreshTable();
  }

  onRoomChange(room: IRoom) {
    if (room) {
      this.selectedRoom = room;
    } else {
      this.ddRooms =
        this.ddWards.find((w) => w.id == this.selectedWard?.id)?.rooms ||
        this.ddWards.flatMap((w) => w.rooms);
    }

    this.refreshTable();
  }

  async searchPatientsForm() {
    const searchParams: ISearchInpatient = {};

    if (this.selectedBuilding != null) {
      searchParams.buildingId = this.selectedBuilding.id;
    }

    if (this.selectedFloor != null) {
      searchParams.floorId = this.selectedFloor.id;
    }

    if (this.selectedWard != null) {
      searchParams.wardId = this.selectedWard.id;
    }

    if (this.selectedRoom != null) {
      searchParams.roomId = this.selectedRoom.id;
    }

    this.isLoading = true;
    await this.inpatientService
      .getInpatients(
        searchParams,
        this.paginator.pageSize,
        this.paginator.pageIndex + 1
      )
      .subscribe({
        next: (d) => {
          this.data = new MatTableDataSource(
            d?.data?.map((d: IInpatient) => d)
          );

          this.tableData = d?.data?.map((d: IInpatient) => d);

          this.metricsData = this.tableData.map((d) => {
            if (!d.bedIsAvailable) {
              return {
                id: d.id,
                sepsis: Math.floor(Math.random() * 5),
                saps2: Math.floor(Math.random() * (163 + 1)),
                apache2: Math.floor(Math.random() * (71 + 1)),
                apache4: Math.floor(Math.random() * (30 + 1)) + 256,
                sofa: Math.floor(Math.random() * (24 + 1)),
                ards: Math.floor(Math.random() * (300 + 1)),
                mews: Math.floor(Math.random() * (13 + 1)) + 2,
                glasgow: Math.floor(Math.random() * (12 + 1)) + 3,
              };
            } else {
              return undefined;
            }
          });

          this.totalRecords = d.totalRecords || 0;

          this.isLoading = false;
        },
        error: (err) => {},
      });
  }

  getDropdownData() {
    this.inpatientService.getDropdownData().subscribe({
      next: (d) => {
        this.dropdownData = d.data;
        this.ddBuildings = d.data.buildings;
        this.ddFloors = this.ddBuildings.flatMap((b) => b.floors);
        this.ddWards = this.ddFloors.flatMap((f) => f.wards);
        this.ddRooms = this.ddWards.flatMap((w) => w.rooms);
      },
      error: (err) => {},
    });
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.icu = data['icu'];
    });

    this.keycloakService.getToken().then((token) => {
      if (token) {
        const base64Decoded = this.tokenService.base64UrlDecode(
          token.split('.')[1]
        );
        const payload = JSON.parse(this.tokenService.utf8Decode(base64Decoded));
        this.userRoles = payload?.resource_access?.['ehr-ui']?.roles;

        this.userRoles?.includes('Doctor')
          ? (this.dischargeVisible = true)
          : (this.dischargeVisible = false);

        if (!this.icu) {
          this.isPreRegistration = true;

          this.displayedColumns = [
            'location',
            'bedNumber',
            'patientFullName',
            'patientAge',
            'lengthOfStay',
            // 'admissionDate',
            'inpatient-actions',
          ];
        } else {
          this.isPreRegistration = false;

          this.displayedColumns = [
            'location',
            'bedNumber',
            'patientFullName',
            'patientAge',
            'los',
            'sepsis',
            'saps2',
            'apache2',
            'apache4',
            'sofa',
            'ards',
            'mews',
            'glasgow',
            // 'admissionDate',
            'inpatient-actions',
          ];
        }

        if (this.userRoles.includes('read-only')) {
          this.displayedColumns = this.displayedColumns.filter(
            (column) => column !== 'inpatient-actions'
          );
        }
      }
    });

    this.refreshService.refreshInpatientsList$.subscribe(() => {
      this.searchPatientsForm();
    });

    this.searchPatientsForm();
    this.getDropdownData();
  }

  handleAssignCancel() {
    this.isAssignVisible = false;

    this.bedForm.reset();

    this.bedSubmitted = false;

    this.isLoading = false;
    this.isNewVisitLoading = false;

    this.bedModalRef.destroy();
  }

  handleDischargeCancel() {
    this.isDischargeVisible = false;

    this.dischargeForm.reset();

    this.dischargeSubmitted = false;

    this.isLoading = false;
    this.isNewVisitLoading = false;

    this.dischargeModalRef.destroy();
  }

  clearDataFromServices() {
    this.allergyService.allergyDataSub.next({});
    this.vaccinationService.vaccinationDataSub.next([]);
    this.medicalAlertService.medicalAlertDataSub.next([]);
    this.medicalHistoryService.medicalHistoryDataSub.next([]);
    this.procedureService.procedureDataSub.next({});
    this.medicalDeviceService.medicalDeviceDataSub.next({});
    this.currentProblemsService.currentProblemDataSub.next({});
    this.resolvedProblemsService.resolvedProblemDataSub.next([]);
    this.pregnancyHistoryService.pregnancyHistoryDataSub.next([]);
    this.pregnancyOutcomeService.pregnancyOutcomeDataSub.next([]);
    this.pregnancyStatusService.pregnancyStatusDataSub.next([]);
    this.carePlanService.carePlanDataSub.next([]);
    this.socialHistoryService.socialHistoryDataSub.next([]);
    this.initialAssessmentService.initialAssessmentDataSub.next([]);
    this.travelHistoryService.travelHistoryDataSub.next([]);
    this.currentMedicationService.currentMedicationDataSub.next({});
    this.pastMedicationService.pastMedicationDataSub.next([]);
    this.imagingService.imagingDataSub.next([]);
    this.labService.laboratoryDataSub.next([]);
    this.supportingDocumentsService.supportingDocumentDataSub.next([]);
    this.hysteroscopyFileService.hysteroscopyDataSub.next([]);
    this.vitalSignService.vitalSignDataSub.next([]);
  }

  async submitDischargeForm() {
    this.isDischargeLoading = true;
    this.dischargeSubmitted = true;

    if (this.dischargeForm.valid) {
      let dischargeObj: IAddDischarge = {};
      dischargeObj.episodeCareId = this.dischargeForm.get('eocValue')?.value;
      dischargeObj.finalDiagnosisId =
        this.dischargeForm.get('diagnosisValue')?.value;
      dischargeObj.dischargeTypeId =
        this.dischargeForm.get('dischargeTypeValue')?.value;
      dischargeObj.dischargeTypeNotes =
        this.dischargeForm.get('dischargeTypeNotesValue')?.value || undefined;
      dischargeObj.dischargeSituationId = this.dischargeForm.get(
        'dischargeSituationValue'
      )?.value;
      dischargeObj.dischargeSituationNotes =
        this.dischargeForm.get('dischargeSituationNotesValue')?.value ||
        undefined;

      this.dischargeService.addDischarge(dischargeObj).subscribe({
        next: async (res) => {
          this.isDischargeLoading = false;

          // UPDATE TABLE
          await this.searchPatientsForm().then(() => {
            this.isDischargeVisible = false;
          });

          this.dischargeForm.setErrors(null);
          this.bedForm.setErrors(null);

          this.dischargeModalRef.destroy();

          this.dischargeSubmitted = false;

          Swal.fire({
            text: `${this.translate.instant(
              'Patient discharged successfully'
            )}!`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });
        },
        error: (err) => {
          this.isDischargeLoading = false;

          Swal.fire({
            text: `${this.translate.instant('Unable to discharge patient')}!`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });
    } else {
      this.isDischargeLoading = false;
    }
  }

  async submitBedForm() {
    this.isAssignLoading = true;
    this.bedSubmitted = true;

    if (this.bedForm.valid) {
      this.eocService
        .createInpatient(
          this.bedForm.get('patientValue')?.value || undefined,
          this.bedForm.get('bedValue')?.value || undefined,
          this.bedForm.get('methodValue')?.value || undefined,
          this.bedForm.get('noteValue')?.value || undefined
        )
        .subscribe({
          next: async (res) => {
            // SET EOC AND VISIT IDs
            res?.data?.episodeOfCareId?.toString() &&
              this.eocService.currentEocId.set(
                res?.data?.episodeOfCareId?.toString()
              );

            res?.data?.visitId?.toString() &&
              this.eocService.currentVisitId.set(
                res?.data?.visitId?.toString()
              );

            // SET PATIENT ID
            this.patientService.patientIDsignal.set(
              this.bedForm.get('patientValue')?.value
            );

            this.isAssignLoading = false;

            // UPDATE TABLE
            await this.searchPatientsForm().then(() => {
              this.isAssignVisible = false;
            });

            this.dischargeForm.setErrors(null);
            this.bedForm.setErrors(null);

            this.bedModalRef.destroy();

            this.bedSubmitted = false;

            // RESET DATA  BEFORE ACCESSING A PATIENT
            this.clearDataFromServices();

            // WAIT 100MS FOR THE HTML MODAL TO BE UPDATED AND CLOSED
            // AND THEN PROCEED WITH THE REDIRECTION
            setTimeout(() => {
              this.router.navigate([
                '/ehr/inPatientView',
                this.patientService.patientIDsignal(),
                this.eocService.currentEocId(),
                this.eocService.currentVisitId(),
              ]);
            }, 500);

            Swal.fire({
              text: `${this.translate.instant(
                'Patient assigned to bed successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            this.isAssignLoading = false;

            Swal.fire({
              text: `${this.translate.instant(
                'Unable to assign patient to bed'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
    } else {
      this.isAssignLoading = false;
    }
  }

  handleTypeChange(event: any) {
    if (event == 4) {
      this.dischargeForm.get('dischargeSituationValue')?.setValue(5);
      this.situationDisabled = true;
    } else {
      this.dischargeForm.get('dischargeSituationValue')?.setValue(undefined);
      this.situationDisabled = false;
    }
  }

  handleDischarge(item: IInpatient) {
    this.dischargeForm.reset();

    this.dischargeForm
      .get('eocValue')
      ?.setValue(item?.inPatientInfo?.episodeOfCareId);

    this.isDischargeVisible = true;
    this.isDischargeLoading = true;

    this.dischargeService
      .getDischargeDropdown(item?.inPatientInfo?.episodeOfCareId?.toString())
      .subscribe({
        next: (res) => {
          this.dischargeDropdown = res.data;

          this.finalDiagnosis = res.data.finalDiagnostic.map((d) => ({
            label: d?.name,
            value: d?.id,
          }));

          this.dischargeForm
            .get('diagnosisValue')
            ?.setValue(res.data.potentialDiagnosis.id);

          this.isDischargeLoading = false;

          this.paginator.pageIndex = 0;
          this.searchPatientsForm();
        },
        error: (err) => {
          Swal.fire({
            text: `${this.translate.instant(
              'Unable to retrieve discharge options'
            )}!`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });
  }

  handleAdd(item: IInpatient) {
    this.bedForm.reset();
    if (item.id) {
      this.bedForm.get('bedValue')?.setValue(item?.id);
      this.isAssignVisible = true;
      this.isAssignLoading = true;
      this.inpatientService.getAvailablePatients().subscribe({
        next: (d) => {
          this.availablePatients = d.data.availablePatients.map((d) => {
            return {
              label: d.fullName,
              value: d.id,
            };
          });

          this.reasons = d.data.admissionReasons;
          this.isAssignLoading = false;
        },
        error: (err) => {
          Swal.fire({
            text: `${this.translate.instant(
              'Unable to retrieve available Patients'
            )}!`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });
    }
  }

  handleView(item: IInpatient) {
    this.isLoading = true;
    if (item?.inPatientInfo?.episodeOfCareId) {
      // REQUEST NEW VISIT AND ASSIGN VISIT ID TO EOC SERVICE THEN NAVIGATE
      // this.eocService.currentVisitId.set(res?.data?.visitId?.toString());
      // this.router.navigate(['/inPatientView']);
      Swal.fire({
        title: `<span style="font-size: 20px;">${this.translate.instant(
          'Create New Visit'
        )}</span> <span style="font-size: 16px; font-weight: normal">${this.translate.instant(
          '(In Patient)'
        )}</span>`,
        html: `${this.translate.instant(
          'Are you sure that you want to create a new visit for'
        )} <span style="font-weight: bold;">${
          item?.inPatientInfo?.patientFullName
        }</span> ?`,
        showDenyButton: false,
        showCancelButton: true,
        cancelButtonText: `${this.translate.instant('Cancel')}`,
        confirmButtonText: `${this.translate.instant('Ok')}`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isNewVisitLoading = true;
          this.eocService
            .createNewVisit(item.inPatientInfo.episodeOfCareId)
            .subscribe({
              next: (res) => {
                res?.data?.episodeOfCareId?.toString() &&
                  this.eocService.currentEocId.set(
                    res?.data?.episodeOfCareId?.toString()
                  );

                res?.data?.visitId?.toString() &&
                  this.eocService.currentVisitId.set(
                    res?.data?.visitId?.toString()
                  );

                this.isLoading = false;
                this.isNewVisitLoading = false;
                this.patientService.patientIDsignal.set(
                  item.inPatientInfo?.patientId?.toString()
                );

                // RESET DATA  BEFORE ACCESSING A PATIENT
                this.clearDataFromServices();

                this.router.navigate([
                  '/ehr/inPatientView',
                  this.patientService.patientIDsignal(),
                  this.eocService.currentEocId(),
                  this.eocService.currentVisitId(),
                ]);

                Swal.fire({
                  text: `${this.translate.instant(
                    'New Visit created successfully'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });
              },
              error: (err) => {
                Swal.fire({
                  text: `${this.translate.instant(
                    'Unable to create New Visit'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });
              },
            });
        } else {
          this.isLoading = false;
          this.isNewVisitLoading = false;
        }
      });
    }
  }

  openMove(item: IInpatient) {
    this.selectedPatientFullName = item.inPatientInfo.patientFullName;
    this.selectedPatientID = item.inPatientInfo.id;
    this.isMoveVisible = true;
  }

  refreshTable() {
    this.paginator.pageIndex = 0;
    this.searchPatientsForm();
  }
}
