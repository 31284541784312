import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IProblemsDetailed } from '../interfaces/Problems/IProblemsDetailed';
import { IAddProblem } from '../interfaces/Problems/IAddProblem';
import { IProblemDropdown } from '../interfaces/Problems/IProblemDropdown';
import { IRareDisease } from '../interfaces/Problems/IRareDiseaseDropdown';
import { IIllnessDisorderDropdown } from '../interfaces/Problems/IIllnessDisorderDropdown';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ResolvedProblemsService {
  public resolvedProblemModalSub = new BehaviorSubject<boolean>(false);

  public resolvedProblemEditSub = new BehaviorSubject<
    IProblemsDetailed | undefined
  >(undefined);

  public resolvedProblemDataSub = new BehaviorSubject<IProblemsDetailed[]>([]);

  public resolvedProblemLoadingSub = new BehaviorSubject<boolean>(false);
  public resolvedProblemLoadingSub$ =
    this.resolvedProblemLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.resolvedProblemLoadingSub.next(true);
    await this.getProblems(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.resolvedProblemDataSub.next(response?.data!);
        this.resolvedProblemLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant('Unable to retrieve problems')}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching procedures:', err);
      },
    });
  }

  getProblems(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IProblemsDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IProblemsDetailed[]>>(
        `${environment.BACKEND_URL}Problems/ResolvedProblems/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IProblemsDetailed[]>>(
        `${environment.BACKEND_URL}Problems/ResolvedProblems/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  getProblemDropdown(): Observable<IGenericServiceResponse<IProblemDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IProblemDropdown>>(
      `${environment.BACKEND_URL}Problems/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getRareDiseaseDropdown(): Observable<IGenericServiceResponse<IRareDisease>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IRareDisease>>(
      `${environment.BACKEND_URL}Problems/DropdownData/RareDisease`,
      {
        headers: headers,
      }
    );
  }

  getIllnessDisorderDropdown(): Observable<
    IGenericServiceResponse<IIllnessDisorderDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IIllnessDisorderDropdown>>(
      `${environment.BACKEND_URL}Problems/DropdownData/IllnessAndDisorder`,
      {
        headers: headers,
      }
    );
  }

  addProblem(problemObj: IAddProblem) {
    problemObj.visitId = this.eocService.currentVisitId();
    problemObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}Problems`, problemObj, {
      headers: headers,
    });
  }

  editProblem(problemObj: IAddProblem) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Problems/${problemObj.id}`,
      problemObj,
      {
        headers: headers,
      }
    );
  }

  deleteProblems(problemId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Problems/${problemId}`,
      {
        headers: headers,
      }
    );
  }

  deleteResolvedProblemFromTable(resolvedProblemId: string) {
    // REMOVE THE Resolved Problem
    this.resolvedProblemDataSub.next(
      this.resolvedProblemDataSub.value?.filter(
        (rp) => rp.id?.toString() != resolvedProblemId
      )
    );
  }
}
