<nz-spin [nzSpinning]="isAnyFileProcessing" nzSize="large">
  <div class="App" *ngIf="!examinationStopped">
    <div class="main-container">
      <div class="left-column">
        <div class="top-left-logo">
          <img
            src="../../../../../../../assets/images/HysteroscopyExamination/3aLogo.svg"
            alt="3A Logo"
          />
        </div>

        <div class="video-container">
          <div class="overlay">
            <div class="select-status-container">
              <select
                [(ngModel)]="selectedDeviceId"
                (change)="switchCamera(selectedDeviceId)"
              >
                <option
                  *ngFor="let device of devices"
                  [value]="device.deviceId"
                >
                  {{ device.label }}
                </option>
              </select>
              <span>:</span>
              <h3 style="padding-left: 5px; margin: 0">
                <span #statusColor>
                  <span #statusText>Live</span>
                </span>
              </h3>
            </div>
            <div class="right-info">
              <span>
                {{
                  hysteroscopyDropdown?.anatomicalPosition?.at(
                    currentAnatomicalPositionIndex
                  )?.name
                }}
                ({{ currentAnatomicalPositionIndex + 1 }}/{{
                  hysteroscopyDropdown?.anatomicalPosition?.length
                }})
              </span>
            </div>
          </div>

          <!-- Video Element -->
          <video #videoElement autoplay [class.hidden]="!showVideo"></video>
          <!-- Image Element -->
          <img
            #imageElement
            [class.hidden]="showVideo"
            [src]="
              images[currentAnatomicalPositionIndex].location ||
              '../../../../../../../assets/images/HysteroscopyExamination/uterus3d.png'
            "
            alt="Uterus 3D"
          />
        </div>
      </div>
      <div class="right-column">
        <div class="button-container">
          <!-- New button to toggle between video and image -->
          <button
            class="hysteroscopy-exam-btn btn-switch-view"
            (click)="toggleVideoVisibility()"
            nz-tooltip
            [nzTooltipTitle]="
              (showVideo ? 'Show Image' : 'Show Video') | translate
            "
            nzTooltipPlacement="left"
          >
            <i nz-icon nzType="icons:hysteroscopySwitchView"></i>
          </button>
          <button
            class="hysteroscopy-exam-btn btn-picture"
            (click)="takePicture()"
            [disabled]="!videoElement || isAnyFileProcessing"
            nz-tooltip
            nzTooltipTitle="{{ 'Take picture' | translate }}"
            nzTooltipPlacement="left"
          >
            <i nz-icon nzType="icons:hysteroscopyExamPicture"></i>
          </button>
          <button
            class="hysteroscopy-exam-btn btn-back"
            (click)="navigatePositions(-1)"
            [disabled]="
              currentAnatomicalPositionIndex === 0 ||
              !videoElement ||
              recording ||
              isAnyFileProcessing
            "
            nz-tooltip
            [nzTooltipTitle]="
              (currentAnatomicalPositionIndex === 0 ||
              !videoElement ||
              recording ||
              isAnyFileProcessing
                ? 'No previous anatomical position'
                : 'Previous anatomical position'
              ) | translate
            "
            nzTooltipPlacement="left"
          >
            <i nz-icon nzType="icons:hysteroscopyExamBack"></i>
          </button>
          <button
            [ngClass]="
              paused
                ? 'hysteroscopy-exam-btn btn-play'
                : 'hysteroscopy-exam-btn btn-pause'
            "
            (click)="togglePauseResume()"
            [disabled]="!videoElement || isAnyFileProcessing"
            nz-tooltip
            [nzTooltipTitle]="
              (paused ? 'Continue video' : 'Pause video') | translate
            "
            nzTooltipPlacement="left"
          >
            <i *ngIf="paused" nz-icon nzType="icons:hysteroscopyExamPlay"></i>
            <i *ngIf="!paused" nz-icon nzType="icons:hysteroscopyExamPause"></i>
          </button>
          <button
            class="hysteroscopy-exam-btn btn-next"
            (click)="navigatePositions(1)"
            [disabled]="
              currentAnatomicalPositionIndex ===
                hysteroscopyDropdown?.anatomicalPosition?.length! - 1 ||
              !videoElement ||
              recording ||
              isAnyFileProcessing
            "
            nz-tooltip
            nzTooltipTitle="{{ 'Next anatomical position' | translate }}"
            nzTooltipPlacement="left"
          >
            <i nz-icon nzType="icons:hysteroscopyExamNext"></i>
          </button>

          <button
            class="hysteroscopy-exam-btn btn-stop-recording"
            *ngIf="recording"
            (click)="stopRecording()"
            [disabled]="!videoElement || isAnyFileProcessing"
            nz-tooltip
            nzTooltipTitle="{{ 'Stop recording' | translate }}"
            nzTooltipPlacement="left"
          >
            <i nz-icon nzType="icons:hysteroscopyExamStopRecording"></i>
          </button>
          <button
            class="hysteroscopy-exam-btn btn-start-recording"
            *ngIf="!recording"
            (click)="startRecording()"
            [disabled]="!videoElement || isAnyFileProcessing"
            nz-tooltip
            nzTooltipTitle="{{ 'Start recording' | translate }}"
            nzTooltipPlacement="left"
          >
            <i nz-icon nzType="icons:hysteroscopyExamStartRecording"></i>
          </button>
          <button
            class="hysteroscopy-exam-btn btn-listening"
            (click)="toggleListening()"
            [disabled]="!videoElement || isAnyFileProcessing"
            nz-tooltip
            [nzTooltipTitle]="
              (isListening ? 'Stop listening' : 'Start listening') | translate
            "
            nzTooltipPlacement="left"
          >
            <i
              *ngIf="isListening"
              nz-icon
              nzType="icons:hysteroscopyExamStopListening"
            ></i>
            <i
              *ngIf="!isListening"
              nz-icon
              nzType="icons:hysteroscopyExamStartListening"
            ></i>
          </button>
          <button
            class="hysteroscopy-exam-btn btn-fullscreen"
            (click)="toggleFullScreen()"
            [disabled]="!videoElement || isAnyFileProcessing"
            nz-tooltip
            [nzTooltipTitle]="
              (isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen')
                | translate
            "
            nzTooltipPlacement="left"
          >
            <i
              *ngIf="!isFullscreen"
              nz-icon
              nzType="icons:hysteroscopyExamExitFullscreen"
            ></i>
            <i
              *ngIf="isFullscreen"
              nz-icon
              nzType="icons:hysteroscopyExamEnterFullscreen"
            ></i>
          </button>

          <button
            class="hysteroscopy-exam-btn btn-stop-exam"
            (click)="stopExamination()"
            [disabled]="recording || isAnyFileProcessing"
            nz-tooltip
            nzTooltipTitle="{{ 'Stop examination' | translate }}"
            nzTooltipPlacement="left"
          >
            <i nz-icon nzType="icons:hysteroscopyExamStopExamination"></i>
          </button>
        </div>

        <div *ngIf="isListening" class="slide-right">
          <h3>Listening...🎤 Last command received: {{ lastCommand }}</h3>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="examinationStopped" class="examination-stopped">
    Examination has been stopped. Refresh the page to start over.
  </div>
</nz-spin>
