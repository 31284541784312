import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { IProblemsDetailed } from '../../../../../../interfaces/Problems/IProblemsDetailed';
import { ResolvedProblemsService } from '../../../../../../services/resolvedProblems.service';
import { ResolvedProblemsAddEditComponent } from './resolved-problems-add-edit/resolved-problems-add-edit.component';
import { ResolvedProblemsExpandComponent } from './resolved-problems-expand/resolved-problems-expand.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resolved-problems',
  templateUrl: './resolved-problems.component.html',
  styleUrls: ['./resolved-problems.component.css'],
})
export class ResolvedProblemsComponent {
  public addComp = ResolvedProblemsAddEditComponent;
  public expandComp = ResolvedProblemsExpandComponent;

  private destroy$ = new Subject<void>();

  resolvedProblems?: IProblemsDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.resolvedProblemsService.resolvedProblemLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.resolvedProblemsService.resolvedProblemDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IProblemsDetailed[]) => {
        this.resolvedProblems = data;
      });

    this.resolvedProblemsService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IProblemsDetailed) {
    this.resolvedProblemsService.resolvedProblemModalSub.next(true);
    this.resolvedProblemsService.resolvedProblemEditSub.next(item);
  }

  deleteFun(resolvedProblemId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Resolved / Inactive Problem')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.resolvedProblemsService
          .deleteProblems(resolvedProblemId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Resolved Problem deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.resolvedProblemsService.deleteResolvedProblemFromTable(
                resolvedProblemId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to delete resolved problem'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Resolved Problem is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
}
