/* src/app/components/ehr/admission/admission-profile/admission-medical-info/external-doctors/external-doctors.component.css */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
.add-patient-row {
  margin-bottom: 1.5em;
}
.add-patient-col {
  margin-left: 0.5em;
  margin-right: 2em;
  height: 35px !important;
}
.add-patient-input {
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
.add-patient-inputZorro {
  width: 100%;
}
.identifier-type-select {
  width: 80% !important;
}
::ng-deep nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
select.ng-valid.ng-touched {
  color: black;
}
input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: #c2c2c2;
}
.add-patient-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
.submitted .ng-invalid {
  border: red 1px solid;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
.add-patient-button {
  margin-top: 1em;
  height: 35px;
  border: 1px solid #0d9488;
  border-radius: 4px;
  background-color: #0d9488 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-button {
  margin-right: 1em;
  margin-bottom: 2em;
  height: 35px;
  border: 1px solid #ff2626;
  border-radius: 4px;
  background-color: white;
  color: #ff2626;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
.back-button {
  cursor: pointer;
}
::ng-deep ::ng-deep nz-select-top-control {
  height: 35px !important;
}
.navigation-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.navigation-controls button {
  border-radius: 4px;
  border: 1px solid #317d88;
  background-color: white;
  color: #317d88;
  cursor: pointer;
}
.navigation-controls button:hover {
  color: white !important;
  background-color: #317d88 !important;
}
.nav-btn {
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  scale: 0.7;
}
.nav-btn-plus,
.nav-btn-delete {
  border-color: none !important;
  scale: 0.7;
}
.nav-btn-plus:disabled,
.nav-btn-plus:disabled:hover,
.nav-btn-delete:disabled,
.nav-btn-delete:disabled:hover {
  border: none !important;
  cursor: not-allowed;
}
.nav-btn-plus:hover {
  border-color: #317d88 !important;
}
.nav-btn-delete:hover {
  border-color: #ff2626 !important;
}
.plus-cricle:hover {
  color: #317d88 !important;
}
.delete:hover {
  color: #ff2626 !important;
}
.navigator {
  margin-left: 1em;
  margin-bottom: 1.5em;
}
::ng-deep ngx-intl-tel-input input {
  height: 35px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::ng-deep ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}
::ng-deep ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}
::ng-deep ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}
::ng-deep ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
.mediator {
  margin-left: 1em;
}
.num-con {
  font-weight: 600;
}
.layout {
  margin-left: 0.5em;
  margin-right: 1em;
}
.w-100 {
  width: 100%;
}
@media only screen and (min-width: 1279px) {
  .large-screen {
    margin-bottom: 1.5em;
  }
}
@media only screen and (max-width: 1279px) {
  .small-screen {
    margin-left: 1.5em;
    padding-right: 1em;
  }
}
.label-row {
  min-height: 50px;
}
.title {
  font-size: 20px;
  font-weight: 500;
  margin-left: 14px;
  border-bottom: 1px solid rgba(102, 102, 102, 0.24);
  margin-bottom: 14px;
}
::ng-deep .ant-select-selector {
  height: 100% !important;
}
::ng-deep .iti input:disabled {
  border: 1px solid #d8d8d8;
  height: 32px;
  background-color: rgba(239, 239, 239, 0.3) !important;
  width: 100%;
}
::ng-deep .iti input {
  border: 1px solid #d8d8d8;
  height: 32px;
  background-color: transparent !important;
  width: 100%;
}
::ng-deep .iti {
  width: 100%;
}
ngx-intl-tel-input {
  width: 100%;
}
.add-patient-input:disabled {
  cursor: not-allowed;
}
/*# sourceMappingURL=external-doctors.component-MVY7ANRH.css.map */
