import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IPregnancyHistoryDetailed } from '../interfaces/PregnancyHistory/IPregnancyHistoryDetailed';
import { IAddPregnancyHistory } from '../interfaces/PregnancyHistory/IAddPregnancyHistory';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PregnancyHistoryService {
  public pregnancyHistoryModalSub = new BehaviorSubject<boolean>(false);

  public pregnancyHistoryEditSub = new BehaviorSubject<
    IPregnancyHistoryDetailed | undefined
  >(undefined);

  public pregnancyHistoryDataSub = new BehaviorSubject<
    IPregnancyHistoryDetailed[]
  >([]);

  public pregnancyHistoryLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.pregnancyHistoryLoadingSub.next(true);
    this.getPregnancyHistory(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.pregnancyHistoryDataSub.next(response?.data!);
        this.pregnancyHistoryLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Unable to retrieve Pregnancy History'
          )}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching Pregnancy History:', err);
      },
    });
  }

  getPregnancyHistory(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IPregnancyHistoryDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IPregnancyHistoryDetailed[]>
      >(
        `${environment.BACKEND_URL}PregnancyHistory/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IPregnancyHistoryDetailed[]>
      >(`${environment.BACKEND_URL}PregnancyHistory/${patientId}`, {
        headers: headers,
      });
    }
  }

  addPregnancyHistory(pregnancyHistoryObj: IAddPregnancyHistory) {
    pregnancyHistoryObj.visitId = this.eocService.currentVisitId();
    pregnancyHistoryObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}PregnancyHistory`,
      pregnancyHistoryObj,
      {
        headers: headers,
      }
    );
  }

  editPregnancyHistory(pregnancyHistoryObj: IAddPregnancyHistory) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}PregnancyHistory/${pregnancyHistoryObj.id}`,
      pregnancyHistoryObj,
      {
        headers: headers,
      }
    );
  }

  deletePregnancyHistory(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}PregnancyHistory/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deletePregnancyHistoryFromTable(pregnancyHistoryId: string) {
    // REMOVE THE Pregnancy History
    this.pregnancyHistoryDataSub.next(
      this.pregnancyHistoryDataSub.value?.filter(
        (mh) => mh.id?.toString() != pregnancyHistoryId
      )
    );
  }
}
