import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IGlasgowDetailed } from 'src/app/interfaces/Glasgow/IGlasgowDetailed';
import { GlasgowService } from 'src/app/services/glasgow.service';
import Swal from 'sweetalert2';
import { GlasgowAddEditComponent } from './glasgow-add-edit/glasgow-add-edit.component';
import { glasgowRV } from 'src/app/interfaces/IReferenceValue';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-glasgow',
  templateUrl: './glasgow.component.html',
  styleUrls: ['./glasgow.component.css'],
})
export class GlasgowComponent {
  public addComp = GlasgowAddEditComponent;

  private destroy$ = new Subject<void>();

  glasgow?: IGlasgowDetailed[];
  isLoading: boolean = false;

  referenceValues = glasgowRV;

  constructor(
    private readonly glasgowService: GlasgowService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: IGlasgowDetailed) {
    this.glasgowService.glasgowModalSub.next(true);
    this.glasgowService.glasgowEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Glasgow Record?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.glasgowService.deleteGlasgow(id.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Glasgow deleted successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.glasgowService.deleteGlasgowFromTable(id.toString());
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant('Unable to delete Glasgow')}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Glasgow record is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
  ngOnInit() {
    this.glasgowService.glasgowLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.glasgowService.glasgowDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGlasgowDetailed[]) => {
        this.glasgow = data;
      });

    this.glasgowService.getData();
  }
}
