<div class="margin">
  <div>
    <input #inputFile style="margin-left: 1em" type="file" (change)="onFileSelected($event)" accept=".pdf"
      [disabled]="!isEditMode" multiple />
  </div>

  <div *ngIf="document && document.filePaths">
    <ng-container *ngFor="let doc of document.filePaths">
      <div>
        <span style="margin-left: 1em">
          <em>{{ doc.path }}</em>
          <i nz-icon nzType="download" nzTheme="outline" class="download"
            (click)="downloadDocument(doc.path, doc.id.toString())"></i>
        </span>
        <span *ngIf="document && isEditMode" style="margin-left: 1em">
          <i *ngIf="document" nz-icon nzType="delete" nzTheme="twotone" nzTwotoneColor="#ff0000" class="delete-btn"
            (click)="deleteDocument(doc.id.toString())"></i>
        </span>
      </div>
    </ng-container>
  </div>

</div>