<div class="dashboard-container">
  <div fxLayout="row" class="pb30">
    <div fxLayout="column" fxFlex="10" fxLayoutAlign="center start" class="w-100 title">
      {{ "Dashboard" | translate }}
    </div>
    <div fxLayout="column" fxFlex="90" fxLayoutAlign="center end" class="w-100">
      <button class="export-data-btn" nz-button nzType="primary" (click)="exportData()">
        <i nz-icon nzType="icons:exportIcon"></i>
        {{ "Export Data" | translate }}
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="50px" class="plr30 toggle">
    <app-dashboard-bed-availability-by-ward style="width: 100%"></app-dashboard-bed-availability-by-ward>
  </div>

  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" class="p30 toggle">
    <div fxFlex.gt-sm="50" fxFlex="100">
      <app-dashboard-inpatient-by-gender [isPreRegistration]="false"></app-dashboard-inpatient-by-gender>
    </div>
    <div fxFlex.gt-sm="50" fxFlex="100">
      <app-dashboard-inpatient-by-age></app-dashboard-inpatient-by-age>
    </div>
  </div>

  <div class="daterange-items">
    <div fxLayout="row" fxLayoutAlign="end center" class="p50">
      <nz-range-picker style="border-color: rgba(0, 0, 0, 0.45); border-radius: 4px" [(ngModel)]="dateRange"
        [nzAllowClear]="true" [nzShowToday]="true" [nzFormat]="'dd-MM-YYYY'" [nzDisabledDate]="disabledDate"
        (ngModelChange)="onDateRangeSelected($event)"></nz-range-picker>
    </div>

    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" class="p30">
      <div fxFlex.gt-sm="50" fxFlex="100">
        <app-dashboard-bed-availability-by-day [dateTimeRange]="selectedDates"></app-dashboard-bed-availability-by-day>
      </div>
      <div fxFlex.gt-sm="50" fxFlex="100">
        <app-dashboard-admissions-and-discharges
          [dateTimeRange]="selectedDates"></app-dashboard-admissions-and-discharges>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" class="pa30">
      <div fxFlex.gt-sm="50" fxFlex="100">
        <app-dashboard-admission-reason [dateTimeRange]="selectedDates"></app-dashboard-admission-reason>
      </div>
      <div fxFlex.gt-sm="50" fxFlex="100">
        <app-dashboard-discharge-types [dateTimeRange]="selectedDates"></app-dashboard-discharge-types>
      </div>
    </div>
  </div>
</div>