import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { IGetList } from '../interfaces/Get/IGetList';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IMedicalDeviceDetailed } from '../interfaces/MedicalDevice/IMedicalDeviceDetailed';
import { IMedicalDeviceDropdown } from '../interfaces/MedicalDevice/IMedicalDeviceDropdown';
import { IAddMedicalDevice } from '../interfaces/MedicalDevice/IAddMedicalDevice';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MedicalDeviceService {
  public medicalDeviceModalSub = new BehaviorSubject<boolean>(false);

  public medicalDeviceEditSub = new BehaviorSubject<
    IMedicalDeviceDetailed | undefined
  >(undefined);

  public medicalDeviceDataSub = new BehaviorSubject<
    IGetList<IMedicalDeviceDetailed>
  >({});

  public medicalDeviceLoadingSub = new BehaviorSubject<boolean>(false);
  public medicalDeviceLoadingSub$ = this.medicalDeviceLoadingSub.asObservable();

  public medicalDeviceNoKnownLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.medicalDeviceLoadingSub.next(true);
    await this.getMedicalDevices(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.medicalDeviceDataSub.next(response?.data!);
        this.medicalDeviceLoadingSub.next(false);
        this.medicalDeviceNoKnownLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Unable to retrieve medical devices'
          )}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching medical devices:', err);
      },
    });
  }

  getMedicalDevices(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IGetList<IMedicalDeviceDetailed>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IGetList<IMedicalDeviceDetailed>>
      >(
        `${environment.BACKEND_URL}MedicalDevice/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IGetList<IMedicalDeviceDetailed>>
      >(`${environment.BACKEND_URL}MedicalDevice/${patientId}`, {
        headers: headers,
      });
    }
  }

  getMedicalDeviceDropdown(): Observable<
    IGenericServiceResponse<IMedicalDeviceDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IMedicalDeviceDropdown>>(
      `${environment.BACKEND_URL}MedicalDevice/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addMedicalDevice(medicalDeviceObj: IAddMedicalDevice) {
    medicalDeviceObj.visitId = this.eocService.currentVisitId();
    medicalDeviceObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}MedicalDevice`,
      medicalDeviceObj,
      {
        headers: headers,
      }
    );
  }

  addNoKnownMedicalDevice() {
    const object = {
      patientId: this.patientService.patientIDsignal(),
      visitId: this.eocService.currentVisitId(),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}MedicalDevice/NoKnownMedicalDevices`,
      object,
      {
        headers: headers,
      }
    );
  }

  editMedicalDevice(medicalDeviceObj: IAddMedicalDevice) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}MedicalDevice/${medicalDeviceObj.id}`,
      medicalDeviceObj,
      {
        headers: headers,
      }
    );
  }

  deleteMedicalDevice(medicalDeviceId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}MedicalDevice/${medicalDeviceId}`,
      {
        headers: headers,
      }
    );
  }

  deleteNoKnownMedicalDevice(
    medicalDeviceId: string
  ): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}MedicalDevice/NoKnownMedicalDevices/${medicalDeviceId}`,
      {
        headers: headers,
      }
    );
  }
}
