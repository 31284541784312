import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAdmissionPersonalInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionPersonalInfo';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { PatientService } from 'src/app/services/patient.service';
import { MatDialog } from '@angular/material/dialog';
import { AddPatientService } from 'src/app/services/addpatient.service';
import { ImageCropperComponent } from '../../../register-patient/image-cropper/image-cropper.component';

@Component({
  selector: 'app-admission-personal-info',
  templateUrl: './admission-personal-info.component.html',
  styleUrls: ['./admission-personal-info.component.css'],
})
export class AdmissionPersonalInfoComponent implements OnInit, OnChanges {
  @Input() admissionPersonalInfoData?: IAdmissionPersonalInfo;
  @Input() formSubmitted?: boolean = false;
  @Input() gendersList: ICoding[] = [];
  @Input() bloodTypesList: ICoding[] = [];
  @Input() doctors: NzSelectOptionInterface[] = [];
  @Input() placeOfBirthList: ICoding[] = [];
  @Input() closestRelativesList: ICoding[] = [];
  @Input() educationLevelList: ICoding[] = [];
  @Input() familyStatusList: ICoding[] = [];
  @Input() sourceOfIncomeList: ICoding[] = [];
  @Input() religionList: ICoding[] = [];
  @Input() registrationStatusDropdown: ICoding[] = [];
  @Input() documentTypeList: ICoding[] = [];
  @Input() countriesList: ICoding[] = [];
  @Input() isEditMode: boolean = false;
  @Input() avatarImageUrl?: any;

  editedAvatarImage?: any;

  personalInfoForm: FormGroup = this.formBuilder.group({});

  previousCountryId?: number;
  isPlaceOfBirthCyprus: boolean = false;

  isProfileLoading: boolean = true;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];

  private phoneUtil = PhoneNumberUtil.getInstance();

  constructor(
    private readonly formBuilder: FormBuilder,
    private dialog: MatDialog,
    private addPatientService: AddPatientService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.personalInfoForm = this.formBuilder.group({
      firstName: [
        { value: '', disabled: !this.isEditMode },
        Validators.required,
      ],
      lastName: [
        { value: '', disabled: !this.isEditMode },
        Validators.required,
      ],
      districtOfBirth: [{ value: '', disabled: !this.isEditMode }],
      genderId: [
        {
          value: undefined,
          disabled: !this.isEditMode,
        },
        Validators.required,
      ],
      dateOfBirth: [
        { value: '', disabled: !this.isEditMode },
        [
          Validators.required,
          this.minDateValidator(new Date('1900-01-01')),
          this.maxDateValidator(new Date()),
        ],
      ],
      placeOfBirthId: [
        {
          value: this.admissionPersonalInfoData?.placeOfBirthId,
          disabled: !this.isEditMode,
        },
      ],
      phone: [{ value: '', disabled: !this.isEditMode }],
      email: [
        { value: '', disabled: !this.isEditMode },
        [Validators.required, Validators.email],
      ],
      familyStatusId: [{ value: '', disabled: !this.isEditMode }],
      familyStatusOther: [{ value: '', disabled: !this.isEditMode }],
      closestRelativesId: [{ value: '', disabled: !this.isEditMode }],
      closestRelativesOther: [{ value: '', disabled: !this.isEditMode }],
      educationLevelId: [{ value: '', disabled: !this.isEditMode }],
      educationLevelOther: [{ value: '', disabled: !this.isEditMode }],
      occupation: [{ value: '', disabled: !this.isEditMode }],
      amountOfIncome: [{ value: '', disabled: !this.isEditMode }],
      sourceOfIncomeId: [{ value: '', disabled: !this.isEditMode }],
      religionId: [{ value: '', disabled: !this.isEditMode }],
      religionOther: [{ value: '', disabled: !this.isEditMode }],
      registrationStatusId: [{ value: '', disabled: true }],
      documentDto: this.formBuilder.array([]),
      registrationAgentFullName: [
        {
          value: '',
          disabled: true,
        },
      ],
      dateOfAdmission: [{ value: undefined, disabled: !this.isEditMode }],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['admissionPersonalInfoData'] &&
      this.admissionPersonalInfoData
    ) {
      this.previousCountryId = undefined;
      this.initializeForm();
    }
    if (changes['isEditMode']) {
      this.setFormControlsState();
    }
  }

  initializeForm() {
    this.personalInfoForm.enable();
    this.personalInfoForm.get('registrationAgentFullName')?.disable();
    this.personalInfoForm.patchValue({
      firstName: this.admissionPersonalInfoData?.firstName || '',
      lastName: this.admissionPersonalInfoData?.lastName || '',
      districtOfBirth:
        this.admissionPersonalInfoData?.placeOfBirthDistrict || '',
      genderId: this.admissionPersonalInfoData?.genderId || '',
      dateOfBirth: this.admissionPersonalInfoData?.dateOfBirth || '',
      placeOfBirthId: this.admissionPersonalInfoData?.placeOfBirthId || '',
      // Ensure phone number is correctly formatted
      phone: this.admissionPersonalInfoData?.phone || '',
      email: this.admissionPersonalInfoData?.email || '',
      familyStatusId: this.admissionPersonalInfoData?.familyStatusId || '',
      familyStatusOther:
        this.admissionPersonalInfoData?.familyStatusOther || '',
      closestRelativesId:
        this.admissionPersonalInfoData?.closestRelativesId || '',
      closestRelativesOther:
        this.admissionPersonalInfoData?.closestRelativesOther || '',
      educationLevelId: this.admissionPersonalInfoData?.educationLevelId || '',
      educationLevelOther:
        this.admissionPersonalInfoData?.educationLevelOther || '',
      occupation: this.admissionPersonalInfoData?.occupation || '',
      amountOfIncome: this.admissionPersonalInfoData?.amountOfIncome || '',
      sourceOfIncomeId: this.admissionPersonalInfoData?.sourceOfIncomeId || '',
      religionId: this.admissionPersonalInfoData?.religionId || '',
      religionOther: this.admissionPersonalInfoData?.religionOther || '',
      registrationStatusId:
        this.admissionPersonalInfoData?.registrationStatusId || '',
      documentDto:
        this.admissionPersonalInfoData?.documentDto?.map((doc) => ({
          id: doc.id,
          documentCountryIssuedId: doc.documentCountryIssuedId,
          documentNumber: doc.documentNumber,
          documentTypeId: doc.documentTypeId,
        })) || [],
      registrationAgentFullName:
        this.admissionPersonalInfoData?.registrationAgentFullName,
      dateOfAdmission: this.admissionPersonalInfoData?.dateOfAdmission,
    });

    const documentArray =
      this.admissionPersonalInfoData?.documentDto?.map((doc) =>
        this.formBuilder.group({
          id: [{ value: doc.id }],
          documentCountryIssuedId: [
            { value: doc.documentCountryIssuedId, disabled: true },
          ],
          documentNumber: [{ value: doc.documentNumber, disabled: true }],
          documentTypeId: [{ value: doc.documentTypeId, disabled: true }],
        })
      ) || [];

    // Set the form array
    this.personalInfoForm.setControl(
      'documentDto',
      this.formBuilder.array(documentArray)
    );
    this.personalInfoForm.disable();
  }

  onPlaceOfBirthChange(placeId: number): void {
    this.isPlaceOfBirthCyprus = placeId == 55;

    this.previousCountryId
      ? this.personalInfoForm.get('districtOfBirth')?.setValue(null)
      : undefined;

    this.previousCountryId = placeId;
  }

  setFormControlsState() {
    if (this.isEditMode) {
      this.personalInfoForm.get('firstName')?.enable();
      this.personalInfoForm.get('lastName')?.enable();
      this.personalInfoForm.get('districtOfBirth')?.enable();
      this.personalInfoForm.get('genderId')?.enable();
      this.personalInfoForm.get('dateOfBirth')?.enable();
      this.personalInfoForm.get('placeOfBirthId')?.enable();
      this.personalInfoForm.get('phone')?.enable();
      this.personalInfoForm.get('email')?.enable();
      this.personalInfoForm.get('familyStatusId')?.enable();
      this.personalInfoForm.get('familyStatusOther')?.enable();
      this.personalInfoForm.get('closestRelativesId')?.enable();
      this.personalInfoForm.get('closestRelativesOther')?.enable();
      this.personalInfoForm.get('educationLevelId')?.enable();
      this.personalInfoForm.get('educationLevelOther')?.enable();
      this.personalInfoForm.get('occupation')?.enable();
      this.personalInfoForm.get('amountOfIncome')?.enable();
      this.personalInfoForm.get('sourceOfIncomeId')?.enable();
      this.personalInfoForm.get('religionId')?.enable();
      this.personalInfoForm.get('religionOther')?.enable();
      // this.personalInfoForm.get('registrationStatusId')?.enable();
      this.personalInfoForm.get('dateOfAdmission')?.enable();
    } else {
      this.personalInfoForm.disable();
    }
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  get documentDto(): FormArray {
    return this.personalInfoForm.get('documentDto') as FormArray;
  }
  // Custom validator for minimum date
  minDateValidator(minDate: Date) {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputDate = new Date(control.value);
      if (inputDate < minDate) {
        return { minDate: true };
      }
      return null;
    };
  }

  // Custom validator for maximum date
  maxDateValidator(maxDate: Date) {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputDate = new Date(control.value);
      if (inputDate > maxDate) {
        return { maxDate: true };
      }
      return null;
    };
  }

  uploadAndOpenImageCropper(): void {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.dialog.open(ImageCropperComponent, {
          width: '600px',
          data: { imageChangedEvent: { target: { files: [file] } } },
        });

        this.dialog.afterAllClosed.subscribe({
          next: () => {
            if (this.addPatientService.getProfilePicture() != null) {
              const UrlFromBlob = this.addPatientService.getProfilePicture();
              this.editedAvatarImage = UrlFromBlob;
              this.avatarImageUrl = URL.createObjectURL(UrlFromBlob!);
            } else {
              this.editedAvatarImage = undefined;
              this.avatarImageUrl = undefined;
            }
          },
        });
      };
      reader.readAsDataURL(file);
    });
    fileInput.click();
  }
}
