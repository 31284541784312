import {
  Component,
  Input,
  SimpleChanges,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { IGetExternalDoctor } from 'src/app/interfaces/ExternalDoctors/IGetExternalDoctor';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { ICoding, ICodingSpecialties } from 'src/app/interfaces/ICoding';
import { IExternalDoctor } from 'src/app/interfaces/ExternalDoctors/IExternalDoctor';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-external-doctors',
  templateUrl: './external-doctors.component.html',
  styleUrls: ['./external-doctors.component.css'],
})
export class ExternalDoctorsComponent implements OnInit, OnChanges {
  @Input() externalDoctorsDropdown: NzSelectOptionInterface[] = [];
  @Input() externalDoctorsApi: IGetExternalDoctor[] = [];
  @Input() admissionExternalDoctors: IExternalDoctor[] = [];
  @Input() isEditMode: boolean = false;
  @Input() formSubmitted?: boolean = false;
  @Input() countriesList: ICoding[] = [];
  @Input() doctorSpecialty1: ICodingSpecialties[] = [];
  @Input() doctorSpecialty2: ICodingSpecialties[] = [];
  @Input() doctorSpecialty3: ICodingSpecialties[] = [];

  externalDoctorsForm: FormGroup;
  currentPage: number = 0;
  isExternalDoctor = false;
  isCountryCyprus: boolean = false;
  allSpecialties: ICodingSpecialties[] = [];

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService
  ) {
    this.externalDoctorsForm = this.formBuilder.group({
      externalDoctors: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.loadAllSpecialties();
    this.setExternalDoctors(this.admissionExternalDoctors || []);
    this.cdr.detectChanges();
  }

  loadAllSpecialties() {
    this.allSpecialties = [
      ...this.doctorSpecialty1,
      ...this.doctorSpecialty2,
      ...this.doctorSpecialty3,
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['admissionExternalDoctors'] && this.admissionExternalDoctors) {
      this.setExternalDoctors(this.admissionExternalDoctors || []);
      this.cdr.markForCheck();
    }

    if (changes['isEditMode']) {
      this.setFormControlsState();
      this.cdr.markForCheck();
    }
  }

  get externalDoctors(): FormArray {
    return this.externalDoctorsForm.get('externalDoctors') as FormArray;
  }

  setExternalDoctors(doctors: IExternalDoctor[]) {
    // Preserve existing IDs for doctors already in the FormArray
    const existingDoctors = this.externalDoctors.controls.map(
      (control) => control.value
    );

    const doctorFGs: FormGroup[] = doctors.map((doctor, index) => {
      // Attempt to find a matching doctor in the existing FormArray
      const existingDoctor = existingDoctors.find(
        (d) => d.doctorId === doctor.id
      );

      return this.createDoctorFormGroup({
        ...doctor,
        id: existingDoctor?.doctorId || doctor.id, // Preserve the existing ID
      });
    });

    const doctorFormArray = this.formBuilder.array(doctorFGs);
    this.externalDoctorsForm.setControl('externalDoctors', doctorFormArray);

    // Reset pagination
    this.currentPage = 0;

    this.cdr.detectChanges();
  }

  createDoctorFormGroup(doctor: IExternalDoctor | null): FormGroup {
    // Map countryId from the addressData
    const countryId = doctor?.addressData?.countryId || null;

    return this.formBuilder.group({
      doctorId: [
        { value: doctor?.id || 0, disabled: !this.isEditMode },
        // Validators.required,
      ],
      firstName: [
        { value: doctor?.firstName || '', disabled: !this.isEditMode },
        Validators.required,
      ],
      lastName: [
        { value: doctor?.lastName || '', disabled: !this.isEditMode },
        Validators.required,
      ],

      phoneNumber: [
        { value: doctor?.phoneNumber || '', disabled: !this.isEditMode },
      ],
      registrationNumber: [
        { value: doctor?.registrationNumber || '', disabled: !this.isEditMode },
        Validators.required,
      ],
      addressData: this.formBuilder.group({
        street: [
          {
            value: doctor?.addressData?.street || '',
            disabled: !this.isEditMode,
          },
        ],
        town: [
          {
            value: doctor?.addressData?.town || '',
            disabled: !this.isEditMode,
          },
        ],
        postCode: [
          {
            value: doctor?.addressData?.postCode || '',
            disabled: !this.isEditMode,
          },
        ],
        district: [
          {
            value: doctor?.addressData?.district || '',
            disabled: !this.isEditMode,
          },
        ],
        streetNumber: [
          {
            value: doctor?.addressData?.streetNumber || '',
            disabled: !this.isEditMode,
          },
        ],
        apartmentNumber: [
          {
            value: doctor?.addressData?.apartmentNumber || '',
            disabled: !this.isEditMode,
          },
        ],
        countryId: [
          {
            value: countryId,
            disabled: !this.isEditMode,
          },
        ],
      }),
      specialties: [
        {
          value:
            doctor?.externalDoctorSpecialties?.map((s) => s.specialtyId) ||
            [] ||
            [],
          disabled: !this.isEditMode,
        },
      ],
      toggleControl: [
        {
          value: doctor?.externalDoctorCymaId !== null,
          disabled: !this.isEditMode,
        },
      ],
      externalDoctorCymaId: [
        {
          value: doctor?.externalDoctorCymaId || null,
          disabled: !this.isEditMode,
        },
      ],
    });
  }

  addNewDoctor() {
    const newDoctorForm = this.createDoctorFormGroup(null);
    this.externalDoctors.push(newDoctorForm);
    this.currentPage = this.externalDoctors.length - 1;
    this.cdr.detectChanges();
  }

  private isUpdating = false;

  onDoctorSelectionChange(externalDoctorCymaId: number, index: number) {
    // Prevent recursive calls
    if (this.isUpdating) return;

    this.isUpdating = true;

    const doctorGroup = this.externalDoctors.at(index) as FormGroup;
    const currentDoctorId = doctorGroup.get('doctorId')?.value;

    const existingIds = this.externalDoctors.controls
      .map((control, idx) =>
        idx !== index ? control.get('externalDoctorCymaId')?.value : null
      )
      .filter((id) => id !== null);

    if (existingIds.includes(externalDoctorCymaId)) {
      // Reset the current dropdown selection and show an error message
      this.externalDoctors.at(index).reset();
      Swal.fire({
        icon: 'error',
        title: 'Duplicate Doctor',
        text: `${this.translate.instant(
          'This doctor is already added in the form'
        )}.`,
        confirmButtonText: 'OK',
        confirmButtonColor: '#d33',
      });
      this.isUpdating = false;
      return;
    }

    // Find the selected doctor data from the API list
    const doctorData = this.externalDoctorsApi.find(
      (doc) => doc.id === externalDoctorCymaId
    );

    if (doctorData) {
      const specialtyIds = [
        doctorData.specialty1,
        doctorData.specialty2,
        doctorData.specialty3,
      ].filter((id) => id);

      // Match the doctor from admissionExternalDoctors to get the ID
      const matchedExistingDoctor = this.admissionExternalDoctors.find(
        (doc) => doc.externalDoctorCymaId === externalDoctorCymaId
      );

      // Get the current form data for merging
      const currentFormData = this.externalDoctors.at(index).value;

      // Ensure addressData exists to prevent undefined errors
      const currentAddressData = currentFormData.addressData || {};

      // Merge the selected doctor's data with any existing manual input
      const updatedDoctor = {
        id: matchedExistingDoctor?.id || currentDoctorId || null,
        firstName: doctorData.firstName || currentFormData.firstName || '',
        lastName: doctorData.lastName || currentFormData.lastName || '',
        phoneNumber:
          doctorData.phoneNumber || currentFormData.phoneNumber || '',
        registrationNumber:
          doctorData.registrationNumber ||
          currentFormData.registrationNumber ||
          '',
        addressData: {
          street:
            `${doctorData.address1 ?? ''} ${
              doctorData.address2 ?? ''
            }`.trim() ||
            currentAddressData.street ||
            '',
          town: doctorData.city || currentAddressData.town || '',
          postCode: doctorData.postalCode || currentAddressData.postCode || '',
          district: currentAddressData.district || '', // Use default value if undefined
          streetNumber: currentAddressData.streetNumber || '',
          apartmentNumber: currentAddressData.apartmentNumber || '',
          countryId:
            doctorData.country?.toLowerCase() === 'cy' ||
            doctorData.country?.toLowerCase() === 'cyprus'
              ? 55
              : currentAddressData.countryId || null,
        },
        specialties:
          specialtyIds.length > 0
            ? specialtyIds
            : currentFormData.specialties || [],
        toggleControl: true, // Indicates the doctor is selected from the dropdown
        externalDoctorCymaId: externalDoctorCymaId || null,
      };

      // Patch the form with the updated doctor data
      this.externalDoctors
        .at(index)
        .patchValue(updatedDoctor, { emitEvent: false });
    }

    this.isUpdating = false;
  }

  setFormControlsState() {
    this.externalDoctors.controls.forEach((control: AbstractControl) => {
      const toggleControl = control.get('toggleControl');
      const externalDoctorCymaId = control.get('externalDoctorCymaId')?.value;

      if (this.isEditMode) {
        if (externalDoctorCymaId) {
          // If CymaId exists, disable the toggle and ensure it's true
          toggleControl?.disable();
          toggleControl?.setValue(true, { emitEvent: false });
          control.get('doctorId')?.enable();

          // Enable the dropdown for doctor selection
          control.get('externalDoctorCymaId')?.enable();

          // Disable all other fields to prevent manual editing
          control.get('firstName')?.disable();
          control.get('lastName')?.disable();
          control.get('phoneNumber')?.disable();
          control.get('registrationNumber')?.disable();
          control.get('addressData')?.disable();
          control.get('specialties')?.disable();
        } else {
          // If no CymaId, allow toggling and enable all fields
          toggleControl?.enable();
          control.get('externalDoctorCymaId')?.enable();
          control.get('firstName')?.enable();
          control.get('lastName')?.enable();
          control.get('phoneNumber')?.enable();
          control.get('registrationNumber')?.enable();
          control.get('addressData')?.enable();
          control.get('specialties')?.enable();
        }
      } else {
        // If not in edit mode, disable all fields
        toggleControl?.disable();
        control.get('externalDoctorCymaId')?.disable();
        control.get('firstName')?.disable();
        control.get('lastName')?.disable();
        control.get('phoneNumber')?.disable();
        control.get('registrationNumber')?.disable();
        control.get('addressData')?.disable();
        control.get('specialties')?.disable();
      }
    });
  }

  get totalPages(): number {
    return this.externalDoctors?.length || 0;
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  resetExternalDoctors() {
    const doctorFormArray = this.externalDoctors;
    while (doctorFormArray.length !== 0) {
      doctorFormArray.removeAt(0);
    }
    this.externalDoctorsForm.reset();
    this.setExternalDoctors(this.admissionExternalDoctors || []);
    this.setFormControlsState();
    this.currentPage = 0;
    this.cdr.detectChanges();
  }

  onExternalDoctorToggle(isExternal: boolean, index: number) {
    const doctorGroup = this.externalDoctors.at(index) as FormGroup;
    const currentDoctorId = doctorGroup.get('doctorId')?.value;

    if (!isExternal) {
      // Enable all controls for manual input
      doctorGroup.enable();
    } else {
      // Disable specific controls for external doctor selection
      doctorGroup.get('specialties')?.disable();
      doctorGroup.get('firstName')?.disable();
      doctorGroup.get('lastName')?.disable();
      doctorGroup.get('phoneNumber')?.disable();
      doctorGroup.get('registrationNumber')?.disable();

      const addressGroup = doctorGroup.get('addressData') as FormGroup;
      if (addressGroup) {
        addressGroup.disable();
      }
    }
    doctorGroup.get('doctorId')?.setValue(currentDoctorId);
  }

  // Method to handle place of birth change
  onCountryChange(placeId: number): void {
    this.isCountryCyprus = placeId === 55; // Cyprus id is 55
  }

  getSpecialtyName(
    specialtyList: ICodingSpecialties[],
    specialtyId?: number
  ): string | null {
    if (!specialtyId) return null;
    const specialty = specialtyList.find((s) => s.id === specialtyId);
    return specialty ? specialty.name : null;
  }

  removeDoctor(index: number) {
    // Remove the doctor from the FormArray
    this.externalDoctors.removeAt(index);

    // Adjust currentPage based on the index of the removed doctor
    if (index === 0) {
      this.currentPage = 0; // Stay on the first page
    } else if (index >= this.externalDoctors.length) {
      this.currentPage = this.externalDoctors.length - 1; // Navigate to the last doctor
    } else {
      this.currentPage = index; // Keep the current page
    }

    this.cdr.detectChanges();
  }
}
