<div class="patients-container">
  <form [formGroup]="searchForm">
    <div class="patients-search">
      <div fxLayout="row" class="search-params-row">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="1" class="">
          <label nz-checkbox (ngModelChange)="onSelectAllChange($event)" formControlName="selectAll" style="width: 100%"
            nz-tooltip="{{ 'Select All' | translate }}"></label>
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="25" class="patients-search-input">
          <nz-select class="registration" nzMode="multiple" nzShowSearch nzAllowClear
            [nzPlaceHolder]="'Admission Status' | translate" formControlName="registrationValue" [nzLoading]="isLoading"
            style="width: 100%" [nzDisabled]="searchForm.get('selectAll')?.value">
            <ng-container *ngFor="let et of dropdownData.registrationStatus">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>

        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="14" class="patients-search-input">
          <nz-range-picker formControlName="dateValue" [nzDisabledDate]="disabledEndDate"
            style="width: 100%"></nz-range-picker>
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="10" class="patients-search-input">
          <input nz-input placeholder="{{ 'Document Number' | translate }}" formControlName="documentValue" type="text"
            style="width: 100%" />
        </div>

        <div fxFlex="auto"></div>
        <div fxLayout="column" fxLayoutAlign="" fxFlex="33" class="patients-search-buttons">
          <div fxLayout="row" fxFlex="100">
            <div fxLayout="column" fxLayoutAlign="" fxFlex="24">
              <button class="search-button btn-align" (click)="searchPatientsForm()">
                <i nz-icon nzType="icons:userSearch"></i>
                <div class="btn-text">{{ "Search" | translate }}</div>
              </button>
            </div>
            <div fxFlex="6"></div>
            <div fxLayout="column" fxLayoutAlign="" fxFlex="24">
              <button class="export-button btn-align" (click)="exportAdmission()">
                <i class="custom-icon" nz-icon nzType="icons:exportIcon"></i>
                <span class="btn-text">{{ "Export" | translate }}</span>
              </button>
            </div>
            <div fxFlex="6"></div>
            <div fxLayout="column" fxLayoutAlign="" fxFlex="40" fxFlex.lt-lg="24">
              <button class="register-button btn-align" (click)="registerPatient()">
                <i nz-icon nzType="icons:userAdd"></i>
                <div class="btn-text">{{ "Register Patient" | translate }}</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" class="search-params-row-2">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="1" class="expand-btn">
          <span nz-tooltip nzTooltipPlacement="top"
            [nzTooltipTitle]="(expand ? 'Hide Advanced Search' : 'Advanced Search') | translate">
            <i nz-icon [nzType]="expand ? 'icons:arrowsup' : 'icons:arrowsdown'" nzTheme="outline"
              (click)="expandClick()"></i>
          </span>
        </div>
        <div [visible]="expand" fxLayout="column" fxLayoutAlign="center start" fxFlex="15"
          class="patients-search-input">
          <input nz-input placeholder="{{ 'First Name' | translate }}" formControlName="firstNameValue" type="text"
            style="width: 100%" />
        </div>
        <div [visible]="expand" fxLayout="column" fxLayoutAlign="center start" fxFlex="15"
          class="patients-search-input">
          <input nz-input placeholder="{{ 'Last Name' | translate }}" formControlName="lastNameValue" type="text"
            style="width: 100%" />
        </div>

        <div [visible]="expand" fxLayout="column" fxLayoutAlign="center start" fxFlex="15"
          class="patients-search-input">
          <input matInput [placeholder]="'Date of Birth' | translate" type="date" formControlName="dateOfBirthValue"
            class="dob-input" [max]="getCurrentDate()">
        </div>

        <div [visible]="expand" fxLayout="column" fxLayoutAlign="center center" fxFlex="15" class="ml-1">
          <label nz-checkbox formControlName="mohValue" style="width: 100%"
            nz-tooltip="{{ 'Chronic patient' | translate }}">{{ "MOH/DMSW" | translate }}</label>
        </div>
      </div>
    </div>
  </form>
  <div class="patients-table">
    <mat-table [dataSource]="data" class="mat-elevation-z8">
      <ng-container matColumnDef="documentNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Document Number" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="documentNumber">
          {{ element?.documentNumber || "-" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef>
          {{ "First Name" | translate }}
          <i nz-icon *ngIf="fnSort==0 || fnField != 'firstName'" nzType="icons:sort" nzTheme="outline" class="sort-btn"
            (click)="sortFn('firstName'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==1 && fnField == 'firstName'" nzType="icons:sortup" nzTheme="outline"
            class="sort-btn" (click)="sortFn('firstName'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==2 && fnField == 'firstName'" nzType="icons:sortdown" nzTheme="outline"
            class="sort-btn" (click)="sortFn('firstName'); $event.stopPropagation()"></i>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="firstName">
          {{ element?.firstName || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef>
          {{ "Last Name" | translate }}
          <i nz-icon *ngIf="fnSort==0 || fnField != 'lastName'" nzType="icons:sort" nzTheme="outline" class="sort-btn"
            (click)="sortFn('lastName'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==1 && fnField == 'lastName'" nzType="icons:sortup" nzTheme="outline" class="sort-btn"
            (click)="sortFn('lastName'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==2 && fnField == 'lastName'" nzType="icons:sortdown" nzTheme="outline"
            class="sort-btn" (click)="sortFn('lastName'); $event.stopPropagation()"></i>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="lastName">
          {{ element?.lastName || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dateOfBirth">
        <mat-header-cell *matHeaderCellDef>
          {{ "Date of Birth" | translate }}
          <i nz-icon *ngIf="fnSort==0 || fnField != 'dateOfBirth'" nzType="icons:sort" nzTheme="outline"
            class="sort-btn" (click)="sortFn('dateOfBirth'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==1 && fnField == 'dateOfBirth'" nzType="icons:sortup" nzTheme="outline"
            class="sort-btn" (click)="sortFn('dateOfBirth'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==2 && fnField == 'dateOfBirth'" nzType="icons:sortdown" nzTheme="outline"
            class="sort-btn" (click)="sortFn('dateOfBirth'); $event.stopPropagation()"></i>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="dateOfBirth">
          {{ element?.dateOfBirth || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef>
          {{ "Age" | translate }}
          <i nz-icon *ngIf="fnSort==0 || fnField != 'age'" nzType="icons:sort" nzTheme="outline" class="sort-btn"
            (click)="sortFn('age'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==1 && fnField == 'age'" nzType="icons:sortup" nzTheme="outline" class="sort-btn"
            (click)="sortFn('age'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==2 && fnField == 'age'" nzType="icons:sortdown" nzTheme="outline" class="sort-btn"
            (click)="sortFn('age'); $event.stopPropagation()"></i>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="age">
          {{ element?.age || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dateOfApplication">
        <mat-header-cell *matHeaderCellDef>
          {{ "Date of Application" | translate }}
          <i nz-icon *ngIf="fnSort==0 || fnField != 'creationDate'" nzType="icons:sort" nzTheme="outline"
            class="sort-btn" (click)="sortFn('creationDate'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==1 && fnField == 'creationDate'" nzType="icons:sortup" nzTheme="outline"
            class="sort-btn" (click)="sortFn('creationDate'); $event.stopPropagation()"></i>
          <i nz-icon *ngIf="fnSort==2 && fnField == 'creationDate'" nzType="icons:sortdown" nzTheme="outline"
            class="sort-btn" (click)="sortFn('creationDate'); $event.stopPropagation()"></i>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="dateOfApplication">
          {{ element?.dateOfApplication || "-" }}
          <span *ngIf="element.daysFromFirstRegistration" style="margin-left: 5px"
            nz-tooltip="{{ 'Days passed since registration' | translate }}" class="daysFromFirstRegistration">
            ({{ element.daysFromFirstRegistration }})</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fileRatio">
        <mat-header-cell *matHeaderCellDef>
          {{ "Documents" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="fileRatio">
          <ng-container *ngIf="
              element?.registrationStatusId == 3 ||
                element.registrationStatusId == 2;
              else dash
            ">
            <span [ngStyle]="{
                color:
                  element.uploadedFileTypes === 0
                    ? 'red'
                    : element.uploadedFileTypes > 0 &&
                      element.uploadedFileTypes < 7
                    ? 'orange'
                    : element.uploadedFileTypes === 7
                    ? 'green'
                    : 'black'
              }">
              {{ element.uploadedFileTypes }} / {{ element.fileTypesCount }}
            </span>
          </ng-container>
          <ng-template #dash>-</ng-template>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="registrationStatus">
        <mat-header-cell *matHeaderCellDef>
          {{ "Admission Status" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <span [ngSwitch]="element?.registrationStatus">
            <span *ngSwitchCase="'Pending'" class="pending">{{
              element?.registrationStatus | translate
              }}</span>
            <span *ngSwitchCase="'Approved'" class="approved" (click)="openModal(element); $event.stopPropagation()">{{
              element?.registrationStatus | translate }}
              <app-admission-popup [patient]="element" [openModalID]="openModalID"></app-admission-popup>
            </span>
            <span *ngSwitchCase="'Rejected'" class="rejected" (click)="openModal(element); $event.stopPropagation()">{{
              element?.registrationStatus | translate }}
              <app-admission-popup [patient]="element" [openModalID]="openModalID"></app-admission-popup>
            </span>
            <span *ngSwitchCase="'Waiting List'" class="waitinglist">{{
              element?.registrationStatus | translate
              }}</span>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="admission-actions">
        <mat-header-cell *matHeaderCellDef>
          {{ "Actions" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="action-buttons-cell">
          <!-- <button
            *ngIf="
              element.registrationStatusId != 5 &&
              element.registrationStatusId != 2 &&
              !isLoading
            "
            class="discharge-button"
            (click)="openRejectModal(element)"
          >
            {{ "Reject" | translate }}
          </button>
          <button
            *ngIf="element.registrationStatusId === 4 && !isLoading"
            class="waitinglist-button"
            (click)="preApprovePatient(element)"
          >
            {{ "Waiting List" | translate }}
          </button>
          <button
            *ngIf="element.registrationStatusId === 3 && !isLoading"
            class="preapprove-button"
            (click)="openAdmitModal(element)"
          >
            {{ "Admit" | translate }}
          </button> -->
          <button class="add-button" (click)="viewPatient(element.id)">
            {{ "View" | translate }}
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="viewPatient(row.id)"></mat-row>
    </mat-table>

    <span>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100" *ngIf="data.data.length == 0 && !isLoading"
        class="no-patients">
        {{"No Patients available." | translate}}
      </div>
    </span>
    <mat-paginator #inpaginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons [ngClass]="
        data.data.length === 0 ? 'patients-content-paginator-hide' : ''
      " (page)="searchPatientsForm()" [length]="totalRecords" />
  </div>

  <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" class="admission-spinner">
    <nz-spin nzSimple></nz-spin>
  </div>
</div>