import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { MatIconModule } from '@angular/material/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { Subject, takeUntil } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import Swal from 'sweetalert2';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import moment from 'moment';
import { IAdmissionFile } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionFile';
import { SupportingDocumentsService } from 'src/app/services/supportingDocuments.service';
import { SupportingDocumentsAddComponent } from './supporting-documents-add/supporting-documents-add.component';

@Component({
  standalone: true,
  selector: 'app-supporting-documents',
  templateUrl: './supporting-documents.component.html',
  styleUrl: './supporting-documents.component.css',
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    TooltipComponent,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NzPaginationModule,
    NgTemplateOutlet,
    BrowserModule,
    PdfViewerModule,
    NzSpinModule,
  ],
})
export class SupportingDocumentsComponent {
  @Input() isPreview: boolean = false;
  @Input() isLoading?: boolean = false;
  isPreviewLoading?: boolean = false;

  @Input() pagination?: boolean = true;

  public addComp = SupportingDocumentsAddComponent;

  private destroy$ = new Subject<void>();

  pdfObj?: string;
  pdfItem?: IAdmissionFile;

  @Input() data?: IAdmissionFile[];

  dataFormatted: IAdmissionFile[] = [];

  currentPageIndex: number = 1;
  pageSize: number = 5;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal
  isVisible = false;

  constructor(
    private readonly supportingDocumentsService: SupportingDocumentsService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.supportingDocumentsService.supportingDocumentLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    if (!this.data) {
      this.supportingDocumentsService.supportingDocumentDataSub
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: IAdmissionFile[]) => {
          this.dataFormatted = [];
          this.data = data;

          this.data.map((rec) => {
            rec.filePaths.map((path) => {
              this.dataFormatted.push({
                id: path.id,
                fileTypeId: rec.fileTypeId,
                fileType: rec.fileType,
                filePaths: [{ id: path.id, path: path.path }],
                notes: rec.notes,
              });
            });
          });

          if (this.dataFormatted.at(0)) {
            this.requestPDF(this.dataFormatted.at(0));
          }
        });

      this.supportingDocumentsService.getData();
    }
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  // hasUnsubmitted() {
  //   return this.getData()?.find(
  //     (d) => d?.['isSubmitted'] !== undefined && !d?.['isSubmitted']
  //   );
  // }

  getData() {
    if (this.pagination) {
      const startIndex = (this.currentPageIndex - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this?.data?.slice(startIndex, endIndex) || [];
    }
    return this?.dataFormatted;
  }

  handlePageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPageIndex = 1; // Reset to the first page when changing page size
  }

  openModal(item?: IAdmissionFile) {
    console.log(item);
    this.supportingDocumentsService.supportingDocumentModalSub.next(true);
    this.supportingDocumentsService.supportingDocumentEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder3'
      )} ${this.translate.instant('Supporting Document')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.supportingDocumentsService
          .deleteSupportingDocumentFile(id.toString())
          .subscribe({
            next: () => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Supporting Document deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              if (id === this?.pdfItem?.id) {
                this.pdfObj = undefined;
                this.pdfItem = undefined;
              }

              this.supportingDocumentsService.getData();
            },
            error: () => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to delete Supporting Document'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Supporting Document is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  requestPDF(item?: IAdmissionFile) {
    this.isPreviewLoading = true;
    this.supportingDocumentsService
      .getPDF(item?.filePaths?.at(0)?.id?.toString()!)
      .subscribe({
        next: (res: Blob | MediaSource) => {
          this.pdfObj = URL.createObjectURL(res);
          this.pdfItem = item;
          this.isPreviewLoading = false;
        },
        error: () => {
          Swal.fire({
            text: `${this.translate.instant(
              'Unable to retrieve the document'
            )}!`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          this.pdfObj = undefined;
          this.pdfItem = undefined;

          this.isPreviewLoading = false;
        },
      });
  }

  downloadPDF(item: IAdmissionFile) {
    this.supportingDocumentsService.downloadPDF(item);
  }

  getValue(field: string, value?: string) {
    if (!value) {
      return '-';
    }

    if (field?.toLowerCase()?.includes('date time')) {
      return moment(value).format('YYYY-MM-DD h:mm:ss a');
    } else if (field?.toLowerCase()?.includes('date')) {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return value;
    }
  }

  handleCancel() {
    this.isVisible = false;

    this.modalRef.destroy();
  }
}
