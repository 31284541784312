import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IMedicalInfoDetailed } from '../interfaces/MedicalInfo/IMedicalInfoDetailed';
import { IAddMedicalInfo } from '../interfaces/MedicalInfo/IAddMedicalInfo';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CarePlanService {
  public carePlanModalSub = new BehaviorSubject<boolean>(false);

  public carePlanEditSub = new BehaviorSubject<
    IMedicalInfoDetailed | undefined
  >(undefined);

  public carePlanDataSub = new BehaviorSubject<IMedicalInfoDetailed[]>([]);

  public carePlanLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService
  ) {}

  async getData() {
    this.carePlanLoadingSub.next(true);
    this.getCarePlan(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.carePlanDataSub.next(response?.data!);
        this.carePlanLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant('Unable to retrieve care plans')}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching care plans:', err);
      },
    });
  }

  getCarePlan(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IMedicalInfoDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IMedicalInfoDetailed[]>>(
        `${environment.BACKEND_URL}CarePlan/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IMedicalInfoDetailed[]>>(
        `${environment.BACKEND_URL}CarePlan/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  addCarePlan(carePlanObj: IAddMedicalInfo) {
    carePlanObj.visitId = this.eocService.currentVisitId();
    carePlanObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}CarePlan`, carePlanObj, {
      headers: headers,
    });
  }

  editCarePlan(carePlanObj: IAddMedicalInfo) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}CarePlan/${carePlanObj.id}`,
      carePlanObj,
      {
        headers: headers,
      }
    );
  }

  deleteCarePlan(carePlanId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}CarePlan/${carePlanId}`,
      {
        headers: headers,
      }
    );
  }

  deleteCarePlanFromTable(carePlanId: string) {
    // REMOVE THE MedicalAlert
    this.carePlanDataSub.next(
      this.carePlanDataSub.value.filter((ma) => ma.id?.toString() != carePlanId)
    );
  }
}
