import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { MatIconModule } from '@angular/material/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IImagingDetailed } from 'src/app/interfaces/Imaging/IImagingDetailed';
import { ImagingService } from 'src/app/services/imaging.service';
import { Subject, takeUntil } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import Swal from 'sweetalert2';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzImageModule } from 'ng-zorro-antd/image';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IMSPrediction } from 'src/app/interfaces/Imaging/IMSPrediction';

@Component({
  standalone: true,
  selector: 'app-multiple-sclerosis-segmentation',
  templateUrl: './multiple-sclerosis-segmentation.component.html',
  styleUrls: ['./multiple-sclerosis-segmentation.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    TooltipComponent,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NzPaginationModule,
    NgTemplateOutlet,
    BrowserModule,
    PdfViewerModule,
    NzSpinModule,
    NzImageModule,
    NzSelectModule,
  ],
})
export class MultipleSclerosisSegmentationComponent {
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

  @Input() imagingRecords: NzSelectOptionInterface[] = [];
  isLoading?: boolean = false;

  private destroy$ = new Subject<void>();

  public selectedImaging?: string;

  public segmentationResult?: IMSPrediction;

  ngOnInit() {
    this.imagingService.imagingLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.imagingService.imagingDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IImagingDetailed[]) => {
        this.imagingRecords =
          data
            .filter((i) => i.categoryId == 8 && i.modalityId == 6)
            .map((i) => ({
              label: i.examTitle + ' ' + i.date,
              value: i.id,
            })) || [];
      });
  }

  constructor(
    private readonly imagingService: ImagingService,
    private readonly translate: TranslateService
  ) {}

  segment() {
    if (this.selectedImaging) {
      this.isLoading = true;

      this.imagingService.segmentMS(this.selectedImaging).subscribe({
        next: (d) => {
          this.segmentationResult = d?.data;
          this.isLoading = false;

          setTimeout(() => {
            const elementPosition =
              this.scrollContainer.nativeElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - 120 - 10;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth', // Smooth scrolling
            });
          }, 100);
        },
        error: (err) => {
          this.isLoading = false;
          Swal.fire({
            text: `${this.translate.instant('Unable to segment')}!`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });
    }
  }
}
