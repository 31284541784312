import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { IMedicalDeviceDropdown } from '../../../../../../../interfaces/MedicalDevice/IMedicalDeviceDropdown';
import { MedicalDeviceService } from '../../../../../../../services/medicalDevice.service';
import { IAddMedicalDevice } from '../../../../../../../interfaces/MedicalDevice/IAddMedicalDevice';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';

@Component({
  standalone: true,
  selector: 'app-medical-device-add-edit',
  templateUrl: './medical-device-add-edit.component.html',
  styleUrls: ['./medical-device-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
    NzNoAnimationModule,
  ],
})
export class MedicalDeviceAddEditComponent {
  private destroy$ = new Subject<void>();

  medicalDeviceDropdown: IMedicalDeviceDropdown | undefined;

  devices: NzSelectOptionInterface[] = [];

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editMedicalDeviceId?: number;

  medicalDeviceSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  medicalDeviceForm: FormGroup = new FormGroup({});

  constructor(
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.medicalDeviceService.getMedicalDeviceDropdown().subscribe({
      next: (pro) => {
        this.medicalDeviceDropdown = pro?.data;

        this.devices = pro?.data.medicalDevice.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });

        this.isLoading = false;
      },
      error: (err) => {},
    });

    this.medicalDeviceForm = this.formBuilder.group({
      medicalDeviceValue: [null, Validators.required],
      medicalDeviceActionValue: [null, Validators.required],
      onSetDateValue: [null, Validators.required],
      removalDateValue: [null],
      descriptionValue: [null, Validators.required],
    });

    this.medicalDeviceService.medicalDeviceEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editMedicalDeviceId = data?.id;

          this.medicalDeviceForm.patchValue({
            medicalDeviceValue: data?.medicalDeviceId,
            medicalDeviceActionValue: data?.medicalDeviceActionId,
            onSetDateValue: data?.onSetDate,
            removalDateValue: data?.removalDate,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editMedicalDeviceId = undefined;

          this?.medicalDeviceForm.reset();
        }
      });

    this.medicalDeviceService.medicalDeviceModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.medicalDeviceForm.reset();

    this.medicalDeviceService.medicalDeviceModalSub.next(false);

    this.medicalDeviceSubmitted = false;

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.medicalDeviceSubmitted = true;

    if (this.medicalDeviceForm.valid) {
      const medicalDeviceObj: IAddMedicalDevice = {};

      medicalDeviceObj.id = this.editMedicalDeviceId;
      medicalDeviceObj.medicalDeviceId =
        this.medicalDeviceForm.value?.medicalDeviceValue;
      medicalDeviceObj.medicalDeviceActionId =
        this.medicalDeviceForm.value?.medicalDeviceActionValue;
      medicalDeviceObj.onSetDate = this.medicalDeviceForm.value?.onSetDateValue;
      medicalDeviceObj.removalDate =
        this.medicalDeviceForm.value?.removalDateValue;
      medicalDeviceObj.description =
        this.medicalDeviceForm.value?.descriptionValue;

      if (this.isEdit) {
        this.medicalDeviceService
          .editMedicalDevice(medicalDeviceObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Medical Device edited successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.medicalDeviceService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: `${this.translate.instant(
                  'Unable to edit Medical Device'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      } else {
        this.medicalDeviceService.addMedicalDevice(medicalDeviceObj).subscribe({
          next: () => {
            Swal.fire({
              text: `${this.translate.instant(
                'Medical Device added successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.medicalDeviceService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Unable to add Medical Device'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
